import { nodeColors } from '@aninix/core/registries'
import { OutlineBox } from '@aninix/core/utils'
import * as paper from 'paper'
import * as React from 'react'

const isWithinThreshold = (a: number, b: number, threshold: number): boolean =>
  Math.abs(a - b) <= threshold

export type SnappingSegment = {
  start: paper.Point
  end: paper.Point
}

const isSegment = (item: SnappingItem): item is SnappingSegment =>
  'start' in item && 'end' in item

export type SnappingItem = paper.Point | SnappingSegment

export type SnappingPoint = paper.Point

export type SnappingOption = {
  snappedPoint: paper.Point
  items: SnappingItem[]
  distance: number
}

export const snapPointToOutlineBox = (
  point: paper.Point,
  box: OutlineBox,
  zoom: number
) => {
  const edgesThreshold = 10 / zoom
  const pointsThreshold = 15 / zoom

  const pointSnappingOptions: SnappingOption[] = []
  const directionSnappingOptions: SnappingOption[] = []

  const points = [
    {
      point: box.topLeft,
      items: [
        { start: box.topLeft, end: box.topRight },
        { start: box.topLeft, end: box.bottomLeft },
        box.topLeft,
        box.topRight,
        box.bottomLeft,
      ],
    },
    {
      point: box.topCenter,
      items: [
        { start: box.topLeft, end: box.topRight },
        { start: box.topCenter, end: box.bottomCenter },
        box.topLeft,
        box.topRight,
        box.bottomCenter,
      ],
    },
    {
      point: box.topRight,
      items: [
        { start: box.topRight, end: box.topLeft },
        { start: box.topRight, end: box.bottomRight },
        box.topLeft,
        box.topRight,
        box.bottomRight,
      ],
    },
    {
      point: box.leftCenter,
      items: [
        { start: box.leftCenter, end: box.rightCenter },
        { start: box.topLeft, end: box.bottomLeft },
        box.topLeft,
        box.bottomLeft,
        box.leftCenter,
        box.rightCenter,
      ],
    },
    {
      point: box.center,
      items: [
        { start: box.leftCenter, end: box.rightCenter },
        { start: box.topCenter, end: box.bottomCenter },
        box.topCenter,
        box.leftCenter,
        box.rightCenter,
        box.bottomCenter,
        box.center,
      ],
    },
    {
      point: box.rightCenter,
      items: [
        { start: box.rightCenter, end: box.leftCenter },
        { start: box.topRight, end: box.bottomRight },
        box.topRight,
        box.bottomRight,
        box.leftCenter,
        box.rightCenter,
      ],
    },
    {
      point: box.bottomLeft,
      items: [
        { start: box.bottomLeft, end: box.topLeft },
        { start: box.bottomLeft, end: box.bottomRight },
        box.topLeft,
        box.bottomLeft,
        box.bottomRight,
      ],
    },
    {
      point: box.bottomCenter,
      items: [
        { start: box.bottomCenter, end: box.topCenter },
        { start: box.bottomLeft, end: box.bottomRight },
        box.topCenter,
        box.bottomLeft,
        box.bottomRight,
        box.bottomCenter,
      ],
    },
    {
      point: box.bottomRight,
      items: [
        { start: box.bottomRight, end: box.topRight },
        { start: box.bottomRight, end: box.bottomLeft },
        box.topRight,
        box.bottomLeft,
        box.bottomRight,
      ],
    },
  ]

  points.forEach((p) => {
    if (
      isWithinThreshold(point.x, p.point.x, pointsThreshold) &&
      isWithinThreshold(point.y, p.point.y, pointsThreshold)
    ) {
      pointSnappingOptions.push({
        snappedPoint: p.point,
        items: p.items,
        distance: point.getDistance(p.point),
      })
    }
  })

  const directions = [
    { start: box.topLeft, end: box.topRight },
    { start: box.bottomLeft, end: box.bottomRight },
    { start: box.topLeft, end: box.bottomLeft },
    { start: box.topRight, end: box.bottomRight },
    { start: box.topCenter, end: box.bottomCenter },
    { start: box.leftCenter, end: box.rightCenter },
  ]

  directions.forEach((direction) => {
    const start = direction.start
    const end = direction.end
    const lineVec = end.subtract(start)
    const pointVec = point.subtract(start)
    const lineLengthSquared = lineVec.dot(lineVec)
    const t =
      lineLengthSquared !== 0 ? pointVec.dot(lineVec) / lineLengthSquared : 0
    const projectionPoint = start.add(lineVec.multiply(t))
    const distance = point.getDistance(projectionPoint)

    if (distance <= edgesThreshold) {
      const distToStart = projectionPoint.getDistance(start)
      const distToEnd = projectionPoint.getDistance(end)
      const farthestPoint = distToStart > distToEnd ? start : end

      const snapItems = [
        { start, end },
        projectionPoint,
        start,
        end,
        { start: projectionPoint, end: farthestPoint },
      ]

      directionSnappingOptions.push({
        snappedPoint: projectionPoint,
        items: snapItems,
        distance: distance,
      })
    }
  })

  if (pointSnappingOptions.length > 0) {
    const bestOption = pointSnappingOptions.reduce((prev, curr) => {
      return prev.distance < curr.distance ? prev : curr
    })

    return {
      snappedPoint: bestOption.snappedPoint,
      items: bestOption.items,
    }
  } else if (directionSnappingOptions.length > 0) {
    const bestOption = directionSnappingOptions.reduce((prev, curr) => {
      return prev.distance < curr.distance ? prev : curr
    })

    return {
      snappedPoint: bestOption.snappedPoint,
      items: bestOption.items,
    }
  } else {
    return {
      snappedPoint: point,
      items: [],
    }
  }
}

export enum SnapConstraints {
  Horizontal = 1,
  Vertical = 2,
  Diagonal = 3,
}

type SnapPointToPoints = (
  args: {
    currentPoint: paper.Point
    targets: paper.Point[]
    distance: number
  } & (
    | {
        constraints?: undefined
        startPoint?: paper.Point
        shouldApplyConstraint?: any
      }
    | {
        constraints: SnapConstraints[]
        startPoint: paper.Point
        shouldApplyConstraint: boolean
      }
  )
) => [paper.Point, SnappingItem[], SnapConstraints?]

export const snapPointToPoints: SnapPointToPoints = ({
  currentPoint,
  distance,
  constraints,
  startPoint,
  shouldApplyConstraint,
  targets,
}) => {
  const intersectionPoints = findIntersectionPoints(targets)
  const intersectionTargets = intersectionPoints.map((ip) => ip.point)
  const allTargets = [...targets, ...intersectionTargets]

  let activeConstraint: SnapConstraints | undefined
  let constrainedPoint: paper.Point | undefined

  if (shouldApplyConstraint && constraints?.length && startPoint) {
    const movementX = currentPoint.x - startPoint.x
    const movementY = currentPoint.y - startPoint.y
    const absX = Math.abs(movementX)
    const absY = Math.abs(movementY)
    const ratio = absX / absY

    if (
      ratio > 0.6 &&
      ratio < 1.4 &&
      constraints.includes(SnapConstraints.Diagonal)
    ) {
      activeConstraint = SnapConstraints.Diagonal
    } else {
      const isXbigger = absX > absY
      if (isXbigger && constraints.includes(SnapConstraints.Horizontal)) {
        activeConstraint = SnapConstraints.Horizontal
      } else if (!isXbigger && constraints.includes(SnapConstraints.Vertical)) {
        activeConstraint = SnapConstraints.Vertical
      }
    }

    if (activeConstraint) {
      constrainedPoint = getConstrainedPoint(
        currentPoint,
        startPoint,
        activeConstraint
      )
    }
  }

  const searchTargets =
    activeConstraint && startPoint ? [...allTargets, startPoint] : allTargets
  const exactMatch = findExactMatch(
    constrainedPoint ?? currentPoint,
    searchTargets,
    distance
  )

  let axisPoint: paper.Point | undefined
  let axisItems: SnappingItem[] = []

  if (!exactMatch) {
    ;[axisPoint, axisItems] = findAxisAlignment(
      constrainedPoint ?? currentPoint,
      allTargets,
      distance,
      activeConstraint,
      startPoint
    )
  }

  const intersectionMatch = exactMatch
    ? intersectionPoints.find((ip) => ip.point.equals(exactMatch))
    : undefined

  const snappedPoint =
    exactMatch ?? axisPoint ?? constrainedPoint ?? currentPoint

  const snappingItems = uniqueSnappingItems([
    ...axisItems,
    ...(exactMatch ? [snappedPoint] : []),
    ...(intersectionMatch?.intersectionPoints || []),
    ...(intersectionMatch
      ? intersectionMatch.intersectionPoints.map((p) => ({
          start: p,
          end: snappedPoint,
        }))
      : []),
    ...(activeConstraint && startPoint
      ? [startPoint, snappedPoint, { start: startPoint, end: snappedPoint }]
      : []),
  ])

  return [snappedPoint, snappingItems, activeConstraint]
}

function findIntersectionPoints(targets: paper.Point[]) {
  const results: { point: paper.Point; intersectionPoints: paper.Point[] }[] =
    []
  const alignmentLines = {
    horizontal: [] as { y: number; points: paper.Point[] }[],
    vertical: [] as { x: number; points: paper.Point[] }[],
  }

  for (const point of targets) {
    let hLine = alignmentLines.horizontal.find((line) => line.y === point.y)
    if (!hLine) {
      hLine = { y: point.y, points: [] }
      alignmentLines.horizontal.push(hLine)
    }
    hLine.points.push(point)

    let vLine = alignmentLines.vertical.find((line) => line.x === point.x)
    if (!vLine) {
      vLine = { x: point.x, points: [] }
      alignmentLines.vertical.push(vLine)
    }
    vLine.points.push(point)
  }

  for (const hLine of alignmentLines.horizontal) {
    for (const vLine of alignmentLines.vertical) {
      const intersectionPoint = new paper.Point(vLine.x, hLine.y)
      const relatedPoints = [...hLine.points, ...vLine.points]
      const uniqueRelatedPoints = uniquePoints(relatedPoints)
      results.push({
        point: intersectionPoint,
        intersectionPoints: uniqueRelatedPoints,
      })
    }
  }

  return results
}

function findExactMatch(
  currentPoint: paper.Point,
  targets: paper.Point[],
  distance: number
): paper.Point | null {
  let closest: paper.Point | null = null
  let closestDist = Infinity

  for (const target of targets) {
    const dist = currentPoint.getDistance(target)
    if (dist <= distance && dist < closestDist) {
      closest = target
      closestDist = dist
    }
  }

  return closest
}

function findAxisAlignment(
  currentPoint: paper.Point,
  targets: paper.Point[],
  distance: number,
  constraint?: SnapConstraints,
  startPoint?: paper.Point
): [paper.Point, SnappingItem[]] {
  let closestDistance = Infinity
  let bestPoint = currentPoint
  let bestItems: SnappingItem[] = []

  for (const p of targets) {
    const xDist = Math.abs(currentPoint.x - p.x)
    const yDist = Math.abs(currentPoint.y - p.y)
    const xPoint = new paper.Point(p.x, currentPoint.y)
    const yPoint = new paper.Point(currentPoint.x, p.y)

    if (constraint && startPoint) {
      if (
        constraint === SnapConstraints.Horizontal &&
        xDist < distance &&
        xDist < closestDistance
      ) {
        closestDistance = xDist
        bestPoint = xPoint
        bestItems = [p, xPoint, { start: p, end: xPoint }]
      } else if (
        constraint === SnapConstraints.Vertical &&
        yDist < distance &&
        yDist < closestDistance
      ) {
        closestDistance = yDist
        bestPoint = yPoint
        bestItems = [p, yPoint, { start: p, end: yPoint }]
      }
    } else {
      if (xDist < distance && xDist < closestDistance) {
        closestDistance = xDist
        bestPoint = xPoint
        bestItems = [p, xPoint, { start: p, end: xPoint }]
      }

      if (yDist < distance && yDist < closestDistance) {
        closestDistance = yDist
        bestPoint = yPoint
        bestItems = [p, yPoint, { start: p, end: yPoint }]
      }
    }
  }

  return [bestPoint, bestItems]
}

function getConstrainedPoint(
  currentPoint: paper.Point,
  startPoint: paper.Point,
  constraint: SnapConstraints
): paper.Point {
  if (constraint === SnapConstraints.Horizontal) {
    return new paper.Point(currentPoint.x, startPoint.y)
  }
  if (constraint === SnapConstraints.Vertical) {
    return new paper.Point(startPoint.x, currentPoint.y)
  }

  const movementX = currentPoint.x - startPoint.x
  const movementY = currentPoint.y - startPoint.y
  const magnitude = Math.sqrt(movementX * movementX + movementY * movementY)
  const signX = Math.sign(movementX)
  const signY = Math.sign(movementY)

  return new paper.Point(
    startPoint.x + magnitude * signX,
    startPoint.y + magnitude * signY
  )
}

function uniquePoints(points: paper.Point[]): paper.Point[] {
  const unique: paper.Point[] = []
  for (const p of points) {
    if (!unique.some((up) => up.equals(p))) {
      unique.push(p)
    }
  }
  return unique
}

function uniqueSnappingItems(items: SnappingItem[]): SnappingItem[] {
  const uniquePointsSet: paper.Point[] = []
  const uniqueLines: { start: paper.Point; end: paper.Point }[] = []
  const result: SnappingItem[] = []

  for (const item of items) {
    if (item instanceof paper.Point) {
      if (!uniquePointsSet.some((p) => p.equals(item))) {
        uniquePointsSet.push(item)
        result.push(item)
      }
    } else {
      const alreadyExists = uniqueLines.some(
        (u) =>
          (u.start.equals(item.start) && u.end.equals(item.end)) ||
          (u.start.equals(item.end) && u.end.equals(item.start))
      )
      if (!alreadyExists) {
        uniqueLines.push(item)
        result.push(item)
      }
    }
  }

  return result
}

export const Snapping: React.FCC<{
  item: SnappingItem
  zoom: number
  transformMatrix?: paper.Matrix
}> = (props) => {
  const { item: providedItem, zoom, transformMatrix } = props

  if (isSegment(providedItem)) {
    const start = transformMatrix
      ? transformMatrix.transform(providedItem.start)
      : providedItem.start
    const end = transformMatrix
      ? transformMatrix.transform(providedItem.end)
      : providedItem.end

    return (
      <line
        x1={start.x}
        y1={start.y}
        x2={end.x}
        y2={end.y}
        stroke={nodeColors.RED}
        strokeWidth={1 / zoom}
        pointerEvents="none"
      />
    )
  }

  const size = 3 / zoom
  const offset = (size * Math.sqrt(2)) / 2

  const item = transformMatrix
    ? transformMatrix.transform(providedItem)
    : providedItem

  return (
    <>
      <line
        x1={item.x - offset}
        y1={item.y - offset}
        x2={item.x + offset}
        y2={item.y + offset}
        stroke={nodeColors.RED}
        strokeWidth={1.5 / zoom}
        pointerEvents="none"
      />
      <line
        x1={item.x - offset}
        y1={item.y + offset}
        x2={item.x + offset}
        y2={item.y - offset}
        stroke={nodeColors.RED}
        strokeWidth={1.5 / zoom}
        pointerEvents="none"
      />
    </>
  )
}
