import { Point2D } from '@aninix-inc/model'
import * as React from 'react'

type Props = {
  tangentPosition: Point2D
  pointPosition: Point2D
  color: string
  isSelected: boolean
  isHovered: boolean
  zoom: number
  onMouseDown: (event: React.MouseEvent) => void
  onMouseUp: (event: React.MouseEvent) => void
  onMouseMove: () => void
  onMouseLeave: () => void
}
export const Tangent = ({
  tangentPosition,
  pointPosition,
  color,
  zoom,
  isSelected,
  isHovered,
  onMouseDown,
  onMouseUp,
  onMouseMove,
  onMouseLeave,
}: Props) => {
  const handleSize = 6 / zoom

  return (
    <>
      <line
        x1={tangentPosition.x}
        y1={tangentPosition.y}
        x2={pointPosition.x}
        y2={pointPosition.y}
        strokeWidth={1 / zoom}
        stroke={color}
      />
      <g>
        <rect
          x={tangentPosition.x}
          y={tangentPosition.y}
          width={handleSize * 2}
          height={handleSize * 2}
          transform={`rotate(45 ${tangentPosition.x} ${
            tangentPosition.y
          }) translate(${-handleSize} ${-handleSize})`}
          fill="transparent"
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseLeave}
        />
        <rect
          x={tangentPosition.x}
          y={tangentPosition.y}
          width={handleSize}
          height={handleSize}
          transform={`rotate(45 ${tangentPosition.x} ${tangentPosition.y}) translate(${
            -handleSize / 2
          } ${-handleSize / 2})`}
          stroke={isSelected ? '#FFFFFF' : color}
          fill={isSelected || isHovered ? color : '#FFFFFF'}
          strokeWidth={1 / zoom}
          pointerEvents="none"
        />
      </g>
    </>
  )
}
