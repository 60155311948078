import { makeAutoObservable } from 'mobx'
import * as React from 'react'

export type PathPoint = {
  regionIndex: number
  pathIndex: number
  pointIndex: number
}
export type PathTangent = {
  regionIndex: number
  pathIndex: number
  pointIndex: number
  tangent: 'start' | 'end'
}

export class PathEditor {
  selection: Set<string> = new Set()

  constructor() {
    makeAutoObservable(this)
  }

  clear = () => {
    this.selection.clear()
  }

  isSelectionEmpty = () => {
    return this.selection.size === 0
  }

  getKey = (item: PathPoint | PathTangent) => {
    const base = `${item.regionIndex}-${item.pathIndex}-${item.pointIndex}`
    if ('tangent' in item) return `${base}-${item.tangent}`
    return base
  }

  select = (items: (PathPoint | PathTangent)[]) => {
    items.forEach((item) => this.selection.add(this.getKey(item)))
  }

  deselect = (item: PathPoint | PathTangent) => {
    this.selection.delete(this.getKey(item))
  }

  toggleSelection = (items: (PathPoint | PathTangent)[]) => {
    items.forEach((item) => {
      const key = this.getKey(item)
      this.selection.has(key)
        ? this.selection.delete(key)
        : this.selection.add(key)
    })
  }

  replaceSelection = (items: (PathPoint | PathTangent)[]) => {
    this.selection = new Set(items.map(this.getKey))
  }

  isSelected = (item: PathPoint | PathTangent) => {
    return this.selection.has(this.getKey(item))
  }

  getSelection = () => {
    return Array.from(this.selection).map((key) => {
      const [regionIndex, pathIndex, pointIndex, tangent] = key.split('-')

      return {
        regionIndex: Number(regionIndex),
        pathIndex: Number(pathIndex),
        pointIndex: Number(pointIndex),
        ...(tangent != null ? { tangent: tangent as 'start' | 'end' } : {}),
      }
    })
  }

  getSelectedPoints(regionIndex?: number) {
    const keys = Array.from(this.selection).filter(
      (item) => item.split('-').length === 3
    )

    const selectedPoints = keys.map((key) => {
      const [regionIndex, pathIndex, pointIndex] = key.split('-').map(Number)
      return { regionIndex, pathIndex, pointIndex }
    })

    if (regionIndex != null) {
      return selectedPoints.filter((point) => point.regionIndex === regionIndex)
    }

    return selectedPoints
  }

  getSelectedTangents(regionIndex?: number) {
    const keys = Array.from(this.selection).filter(
      (item) => item.split('-').length === 4
    )

    const selectedTangents = keys.map((key) => {
      const [regionIndex, pathIndex, pointIndex, tangent] = key.split('-')
      return {
        regionIndex: Number(regionIndex),
        pathIndex: Number(pathIndex),
        pointIndex: Number(pointIndex),
        tangent: tangent as 'start' | 'end',
      }
    })

    if (regionIndex != null) {
      return selectedTangents.filter(
        (tangent) => tangent.regionIndex === regionIndex
      )
    }

    return selectedTangents
  }
}

const Context = React.createContext<PathEditor>(null as any)

export const usePathEditor = (): PathEditor => {
  const context = React.useContext(Context)

  if (context == null) {
    throw new Error(
      'PathEditor context not found. Use PathEditorProvider at the root component.'
    )
  }

  return context
}

export const PathEditorProvider: React.FCC<{ store: PathEditor }> = ({
  store,
  children,
}) => <Context.Provider value={store}>{children}</Context.Provider>
