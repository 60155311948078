import { Point2D } from '@aninix-inc/model'
import { useCursor } from '@aninix/core/utils'
import * as paper from 'paper'
import * as React from 'react'

interface Props {
  absoluteTransformMatrix: paper.Matrix
  pathData: string
  zoom: number
  color: string
  allowCreate: boolean
  onCreatePoint: (point: Point2D) => void
}

export const RegionStroke: React.FC<Props> = ({
  absoluteTransformMatrix,
  pathData,
  zoom,
  color,
  allowCreate,
  onCreatePoint,
}) => {
  const [pointerPosition, setPointerPosition] = React.useState<{
    x: number
    y: number
  } | null>(null)
  const { cursor, setCursor, clearCursor } = useCursor()
  const { a, b, c, d, tx, ty } = absoluteTransformMatrix

  const pathRef = React.useRef<paper.Path | null>(null)

  React.useEffect(() => {
    if (allowCreate) {
      if (pointerPosition) {
        setCursor('pen-add')
      } else {
        if (cursor === 'pen-add') clearCursor()
      }
    }
  }, [pointerPosition, cursor, allowCreate])

  React.useEffect(() => {
    if (!pathRef.current) {
      pathRef.current = new paper.Path(pathData)
      pathRef.current.transform(new paper.Matrix(a, b, c, d, tx, ty))
    }

    return () => {
      if (pathRef.current) {
        pathRef.current.remove()
        pathRef.current = null
      }
    }
  }, [pathData, a, b, c, d, tx, ty])

  const handlePointerMove = React.useCallback((e: React.MouseEvent) => {
    const svgElement = e.currentTarget.closest('svg')
    if (svgElement && pathRef.current) {
      const point = svgElement.createSVGPoint()
      point.x = e.clientX
      point.y = e.clientY
      const svgPoint = point.matrixTransform(
        svgElement.getScreenCTM()?.inverse()
      )

      const paperPoint = new paper.Point(svgPoint.x, svgPoint.y)
      const nearestPoint = pathRef.current.getNearestPoint(paperPoint)

      setPointerPosition({ x: nearestPoint.x, y: nearestPoint.y })
    }
  }, [])

  const handlePointerLeave = React.useCallback(() => {
    setPointerPosition(null)
  }, [])

  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      if (pointerPosition) {
        e.preventDefault()
        e.stopPropagation()
        const inverse = new paper.Matrix(a, b, c, d, tx, ty).invert()
        const transformedPoint = inverse.transform(
          new paper.Point(pointerPosition.x, pointerPosition.y)
        )
        onCreatePoint({ x: transformedPoint.x, y: transformedPoint.y })
      }
    },
    [onCreatePoint, pointerPosition, a, b, c, d, tx, ty]
  )

  return (
    <>
      <g
        style={{ mixBlendMode: 'difference' }}
        transform={`matrix(${a} ${b} ${c} ${d} ${tx} ${ty})`}
      >
        <path
          d={pathData}
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={1 / zoom}
          strokeOpacity={0.5}
          pointerEvents="none"
        />
        <path
          d={pathData}
          fill="none"
          stroke="#000000"
          strokeWidth={1 / zoom}
          style={{ mixBlendMode: 'difference' }}
          strokeOpacity={0.5}
          pointerEvents="none"
        />
      </g>
      <path
        d={pathData}
        fill="none"
        stroke="transparent"
        strokeWidth={6 / zoom}
        transform={`matrix(${a} ${b} ${c} ${d} ${tx} ${ty})`}
        onPointerMove={(e) => {
          allowCreate && handlePointerMove(e)
        }}
        onPointerLeave={handlePointerLeave}
        onMouseDown={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onMouseUp={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onClick={handleClick}
      />

      {pointerPosition && (
        <circle
          cx={pointerPosition.x}
          cy={pointerPosition.y}
          r={3.5 / zoom}
          fill="#FFFFFF"
          stroke={color}
          strokeWidth={1.5 / zoom}
          pointerEvents="none"
        />
      )}
    </>
  )
}
