import { Entity, NodeColorComponent, Point2D } from '@aninix-inc/model'
import { paper } from '@aninix-inc/renderer'
import { useMouseMove } from '@aninix/app-design-system'
import { nodeColors, useCursor } from '@aninix/core'
import { KeyModificator, useSession, useViewport } from '@aninix/core/stores'
import { BezierPoint } from '@aninix/core/vector-helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Tangent } from './tangent'
import { PATH_EDITOR_EPSILON, RegionPointSelection } from './utils'

export interface IProps {
  node: Entity
  point: BezierPoint
  absoluteTransformMatrix: paper.Matrix
  selection: RegionPointSelection
  isMoving: boolean
  onMove: (offset: Point2D) => void
  onStartMove: (handle: RegionHandle) => void
  onEndMove: () => void
  onRemovePoint: () => void
  onRemoveTangent: (tangent: 'start' | 'end') => void
  onToggleTangents: () => void
  onSelect: (handle: RegionHandle) => void
}

export enum RegionHandle {
  Point = 'vertex',
  StartTangent = 'start-tangent',
  EndTangent = 'end-tangent',
}

export const RegionPoint: React.FCC<IProps> = observer(
  ({
    point,
    node,
    absoluteTransformMatrix,
    selection,
    isMoving,
    onStartMove,
    onMove,
    onEndMove,
    onRemovePoint,
    onRemoveTangent,
    onToggleTangents,
    onSelect,
  }) => {
    const { cursor, setCursor, clearCursor } = useCursor()
    const [regionHandle, setRegionHandle] = React.useState<RegionHandle | null>(
      null
    )
    const viewport = useViewport()

    const session = useSession()
    const hasCtrl = session.keyModificators.includes(KeyModificator.Ctrl)
    const hasShift = session.keyModificators.includes(KeyModificator.Shift)
    const hasAlt = session.keyModificators.includes(KeyModificator.Alt)

    const [isPointHovered, setIsPointHovered] = React.useState(false)
    const [isStartTangentHovered, setIsStartTangentHovered] =
      React.useState(false)
    const [isEndTangentHovered, setIsEndTangentHovered] = React.useState(false)

    const { offsetX, offsetY, startListen, isListening } = useMouseMove({
      element: document.getElementById('stage')!,
      listenElementForMouseUpEvent: true,
    })

    const [wasTriggered, setWasTriggered] = React.useState<boolean>(false)

    const color = nodeColors[node.getComponentOrThrow(NodeColorComponent).value]

    const pointPosition = absoluteTransformMatrix.transform(
      new paper.Point(point.point)
    )
    const startTangent = absoluteTransformMatrix.transform(
      new paper.Point(
        point.point.x + point.startTangent.x,
        point.point.y + point.startTangent.y
      )
    )
    const endTangent = absoluteTransformMatrix.transform(
      new paper.Point(
        point.point.x + point.endTangent.x,
        point.point.y + point.endTangent.y
      )
    )

    React.useEffect(() => {
      if (isMoving) {
        setIsStartTangentHovered(false)
        setIsEndTangentHovered(false)
        return
      }

      if (isPointHovered) {
        if (hasAlt) {
          setCursor('pen-remove')
        } else {
          if (cursor === 'pen-remove') clearCursor()
        }
      }

      if (isStartTangentHovered || isEndTangentHovered || isPointHovered) {
        if (hasCtrl) {
          setCursor('arrow-tangent')
        } else {
          if (cursor === 'arrow-tangent') clearCursor()
        }
      }
    }, [
      cursor,
      isMoving,
      isStartTangentHovered,
      isEndTangentHovered,
      isPointHovered,
      hasAlt,
      hasCtrl,
    ])

    const handlePointMouseDown = React.useCallback(
      (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        startListen(e)
        setRegionHandle(RegionHandle.Point)
      },
      [startListen, point]
    )

    const handlePointMouseUp = React.useCallback(
      (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if (wasTriggered) return

        if (hasAlt) {
          onRemovePoint()
          return
        }
        onSelect(RegionHandle.Point)
        if (hasCtrl) {
          onToggleTangents()
        }
      },
      [hasAlt, hasCtrl, wasTriggered, onRemovePoint, onToggleTangents, onSelect]
    )

    const handleTangentMouseDown = React.useCallback(
      (e: React.MouseEvent, handle: RegionHandle) => {
        e.preventDefault()
        e.stopPropagation()
        setRegionHandle(handle)
        startListen(e)
      },
      [startListen]
    )

    const handleTangentMouseUp = React.useCallback(
      (e: React.MouseEvent, handle: RegionHandle) => {
        e.preventDefault()
        e.stopPropagation()

        if (wasTriggered) return

        if (hasCtrl) {
          const tangent = handle === RegionHandle.StartTangent ? 'start' : 'end'

          onRemoveTangent(tangent)
          return
        }

        onSelect(handle)
      },
      [onSelect, onRemoveTangent, hasCtrl, wasTriggered]
    )

    React.useEffect(() => {
      if (!regionHandle) return

      if (!isListening && wasTriggered) {
        setRegionHandle(null)
        setWasTriggered(false)
        onEndMove()
        return
      }

      let movementX = offsetX
      let movementY = offsetY

      if (!wasTriggered) {
        if (Math.max(Math.abs(movementX), Math.abs(movementY)) < 8) return
        setWasTriggered(true)
        onStartMove(regionHandle)
        return
      }

      if (regionHandle === RegionHandle.Point && !selection.isPointSelected) {
        onSelect(RegionHandle.Point)
        return
      }

      if (
        regionHandle === RegionHandle.StartTangent &&
        !selection.isStartTangentSelected
      ) {
        onSelect(RegionHandle.StartTangent)
        return
      }

      if (
        regionHandle === RegionHandle.EndTangent &&
        !selection.isEndTangentSelected
      ) {
        onSelect(RegionHandle.EndTangent)
        return
      }

      onMove({
        x: movementX / viewport.zoom,
        y: movementY / viewport.zoom,
      })
    }, [
      isListening,
      offsetX,
      hasCtrl,
      hasAlt,
      hasShift,
      offsetY,
      regionHandle,
      wasTriggered,
      isMoving,
    ])

    const showTangents =
      ((Math.abs(point.startTangent.x) >= PATH_EDITOR_EPSILON ||
        Math.abs(point.startTangent.y) >= PATH_EDITOR_EPSILON ||
        Math.abs(point.endTangent.x) >= PATH_EDITOR_EPSILON ||
        Math.abs(point.endTangent.y) >= PATH_EDITOR_EPSILON) &&
        (selection.isPointSelected ||
          selection.isPrevPointSelected ||
          selection.isNextPointSelected ||
          selection.isStartTangentSelected ||
          selection.isEndTangentSelected ||
          selection.isNearStartTangentSelected ||
          selection.isNearEndTangentSelected)) ||
      selection.isNextStartTangentSelected ||
      selection.isPrevEndTangentSelected

    return (
      <>
        {showTangents && (
          <>
            <Tangent
              color={color}
              isSelected={selection.isStartTangentSelected}
              isHovered={!isMoving && isStartTangentHovered}
              tangentPosition={startTangent}
              pointPosition={pointPosition}
              zoom={viewport.zoom}
              onMouseDown={(e) =>
                handleTangentMouseDown(e, RegionHandle.StartTangent)
              }
              onMouseUp={(e) =>
                handleTangentMouseUp(e, RegionHandle.StartTangent)
              }
              onMouseMove={() => {
                if (isMoving) return
                setIsStartTangentHovered(true)
                if (hasCtrl) {
                  setCursor('arrow-tangent')
                }
              }}
              onMouseLeave={() => {
                if (isMoving) return
                setIsStartTangentHovered(false)
                clearCursor()
              }}
            />
            <Tangent
              color={color}
              isSelected={selection.isEndTangentSelected}
              isHovered={!isMoving && isEndTangentHovered}
              tangentPosition={endTangent}
              pointPosition={pointPosition}
              zoom={viewport.zoom}
              onMouseDown={(e) =>
                handleTangentMouseDown(e, RegionHandle.EndTangent)
              }
              onMouseUp={(e) =>
                handleTangentMouseUp(e, RegionHandle.EndTangent)
              }
              onMouseMove={() => {
                if (isMoving) return
                setIsEndTangentHovered(true)
                if (hasCtrl) {
                  setCursor('arrow-tangent')
                }
              }}
              onMouseLeave={() => {
                if (isMoving) return
                setIsEndTangentHovered(false)
                clearCursor()
              }}
            />
          </>
        )}

        <circle
          cx={pointPosition.x}
          cy={pointPosition.y}
          r={(selection.isPointSelected ? 4.5 : 3.5) / viewport.zoom}
          fill={selection.isPointSelected || isPointHovered ? color : '#FFFFFF'}
          stroke={selection.isPointSelected ? '#FFFFFF' : color}
          strokeWidth={1.5 / viewport.zoom}
          onMouseDown={handlePointMouseDown}
          onMouseUp={handlePointMouseUp}
          onMouseMove={() => {
            if (isMoving) return
            setIsPointHovered(true)
            if (hasCtrl) setCursor('arrow-tangent')
            if (hasAlt) setCursor('pen-remove')
          }}
          onMouseLeave={() => {
            if (isMoving) return
            setIsPointHovered(false)
            clearCursor()
          }}
        />
      </>
    )
  }
)

RegionPoint.displayName = 'RegionPoint'
