import {
  KeyModificator,
  usePlayback,
  useTools,
  useViewport,
} from '@aninix/core/stores'

import {
  EntityType,
  getAbsoluteTransformMatrix,
  getAnchorPoint,
  getNode,
  getSortedKeyframes,
  getTransformMatrix,
  NodeColorComponent,
  ParentRelationAspect,
  Point2D,
  PositionComponent,
  SpatialPoint2dKeyframe,
  SpatialPoint2dValueComponent,
  TargetRelationAspect,
  TimeComponent,
} from '@aninix-inc/model'
import { useMouseMove } from '@aninix/app-design-system/hooks'
import { nodeColors } from '@aninix/core'
import { Tool, useProject, useSession } from '@aninix/core/stores'
import { useSelection } from '@aninix/editor/hooks/use-selection'
import { useUndoRedo } from '@aninix/editor/hooks/use-undo-redo'
import { observer } from 'mobx-react-lite'
import * as paper from 'paper'
import * as React from 'react'
import {
  getSelection,
  useEntities,
  useEntity,
  useSystem,
} from '../../../updates'

export const SvgKeyframes: React.FC<{ editable: boolean }> = ({ editable }) => {
  const selection = useSelection()
  const undoRedo = useUndoRedo()
  const nodes = selection.getEntitiesByEntityType(EntityType.Node)
  const keyframes = selection.getEntitiesByEntityType(EntityType.Keyframe)
  useEntities(nodes)
  useEntities(keyframes)
  useSystem(selection)

  const nodesToRender = Array.from(
    new Set([
      ...keyframes
        .map((keyframe) => getNode(keyframe))
        .filter((node) => node !== undefined),
      ...nodes,
    ])
  )

  return nodesToRender.flatMap((node) =>
    getSortedKeyframes(node.getComponentOrThrow(PositionComponent)).map(
      (keyframe) => (
        <Keyframe
          key={`selected-state-keyframe-${keyframe.id}`}
          color={nodeColors[node.getComponentOrThrow(NodeColorComponent).value]}
          keyframe={keyframe}
          onEndChange={undoRedo.commitUndo}
          editable={editable}
        />
      )
    )
  )
}

let prevTool: Tool
const Keyframe: React.FCC<{
  color: string
  onStartChange?: () => void
  onEndChange?: () => void
  keyframe: SpatialPoint2dKeyframe
  editable?: boolean
}> = observer(
  ({ keyframe, onStartChange, onEndChange, color, editable = true }) => {
    useEntity(keyframe)
    const targetRef = React.useRef<'vertex' | 'tangent-left' | 'tangent-right'>(
      'vertex'
    )
    const session = useSession()
    const project = useProject()
    const selection = useSelection()
    const playback = usePlayback()
    const viewport = useViewport()
    const tools = useTools()

    const bakedPosition = React.useRef<Point2D>({
      x: 0,
      y: 0,
    })

    const { offsetX, offsetY, startListen, isListening, wasTriggered } =
      useMouseMove({
        threshold: 10,
        element: document.getElementById('stage')!,
        listenElementForMouseUpEvent: true,
        // @NOTE: 120 fps
        delay: 8.3,
        onStart: onStartChange,
        onFinish: onEndChange,
      })

    const isSelected = selection.isSelected(keyframe.id)
    const node = getNode(keyframe)

    const handleTangentMove = React.useCallback(
      ({
        position: newPosition,
        isLeft,
      }: {
        position: Point2D
        isLeft: boolean
      }): void => {
        if (editable === false) {
          return
        }

        if (isLeft) {
          keyframe.updateComponent(SpatialPoint2dValueComponent, (v) => ({
            ...v,
            tx1: newPosition.x,
            ty1: newPosition.y,
          }))
          return
        }

        keyframe.updateComponent(SpatialPoint2dValueComponent, (v) => ({
          ...v,
          tx2: newPosition.x,
          ty2: newPosition.y,
        }))
      },
      [keyframe, editable]
    )

    const handleVertexMove = React.useCallback(
      ({ position: newPosition }: { position: Point2D }): void => {
        if (editable === false) {
          return
        }

        const selectedPositionKeyframes = getSelection(
          project,
          EntityType.Keyframe
        ).filter(
          (_keyframe) =>
            _keyframe
              .getAspectOrThrow(TargetRelationAspect)
              .getComponentTagOrThrow() === PositionComponent.tag &&
            _keyframe.id !== keyframe.id
        )

        const currentKeyframeValue = keyframe.getComponentOrThrow(
          SpatialPoint2dValueComponent
        ).value
        const offset = {
          x: newPosition.x - currentKeyframeValue.x,
          y: newPosition.y - currentKeyframeValue.y,
        }

        selectedPositionKeyframes.forEach((_keyframe) => {
          _keyframe.updateComponent(SpatialPoint2dValueComponent, (v) => ({
            x: v.x + offset.x,
            y: v.y + offset.y,
            tx1: v.tx1 + offset.x,
            ty1: v.ty1 + offset.y,
            tx2: v.tx2 + offset.x,
            ty2: v.ty2 + offset.y,
          }))
        })

        keyframe.updateComponent(SpatialPoint2dValueComponent, (v) => {
          return {
            x: newPosition.x,
            y: newPosition.y,
            tx1: v.tx1 + offset.x,
            ty1: v.ty1 + offset.y,
            tx2: v.tx2 + offset.x,
            ty2: v.ty2 + offset.y,
          }
        })
      },
      [editable, project]
    )

    const toggleVertexTangents = React.useCallback(() => {
      const id = (() => {
        const xEquals =
          keyframeValue.x === keyframeValue.tx1 &&
          keyframeValue.x === keyframeValue.tx2
        const yEquals =
          keyframeValue.y === keyframeValue.ty1 &&
          keyframeValue.y === keyframeValue.ty2

        if (xEquals && yEquals) {
          return 'no-mirroring'
        }

        return 'custom'
      })()

      const nextId = (() => {
        if (id === 'custom') {
          return 'no-mirroring'
        }

        return 'custom'
      })()

      if (nextId === 'custom') {
        const defaultOffset = 50
        keyframe.updateComponent(SpatialPoint2dValueComponent, (v) => ({
          ...v,
          tx1: keyframeValue.x - defaultOffset,
          ty1: keyframeValue.y,
          tx2: keyframeValue.x + defaultOffset,
          ty2: keyframeValue.y,
        }))
      }

      if (nextId === 'no-mirroring') {
        keyframe.updateComponent(SpatialPoint2dValueComponent, (v) => ({
          ...v,
          tx1: keyframeValue.x,
          ty1: keyframeValue.y,
          tx2: keyframeValue.x,
          ty2: keyframeValue.y,
        }))
      }
    }, [keyframe])

    React.useEffect(() => {
      if (isListening === false || wasTriggered === false) {
        return
      }

      if (
        isListening &&
        isSelected === false &&
        targetRef.current === 'vertex'
      ) {
        handleVertexClick()
      }

      const moved = (() => {
        const actualOffsetX = Math.round(offsetX / viewport.zoom)
        const actualOffsetY = Math.round(offsetY / viewport.zoom)

        if (session.keyModificators.includes(KeyModificator.Shift)) {
          const isXBigger = Math.abs(actualOffsetX) > Math.abs(actualOffsetY)
          const isYBigger = Math.abs(actualOffsetX) < Math.abs(actualOffsetY)

          if (isXBigger) {
            return {
              x: actualOffsetX,
              y: 0,
            }
          }

          if (isYBigger) {
            return {
              x: 0,
              y: actualOffsetY,
            }
          }
        }

        return {
          x: actualOffsetX,
          y: actualOffsetY,
        }
      })()

      const preparedPosition = zerifyPosition(moved)

      if (targetRef.current === 'vertex') {
        handleVertexMove({
          position: {
            x: preparedPosition.x,
            y: preparedPosition.y,
          },
        })
        return
      }

      if (targetRef.current === 'tangent-left') {
        handleTangentMove({
          position: {
            x: preparedPosition.x,
            y: preparedPosition.y,
          },
          isLeft: true,
        })
        return
      }

      if (targetRef.current === 'tangent-right') {
        handleTangentMove({
          position: {
            x: preparedPosition.x,
            y: preparedPosition.y,
          },
          isLeft: false,
        })
      }
    }, [isListening, wasTriggered, offsetX, offsetY, isSelected])

    if (node === undefined) {
      return null
    }

    const keyframeTime = keyframe.getComponentOrThrow(TimeComponent).value
    const keyframeValue = keyframe.getComponentOrThrow(
      SpatialPoint2dValueComponent
    ).value
    const anchorPoint = getAnchorPoint(node, keyframeTime)
    const parent = node.getAspectOrThrow(ParentRelationAspect).getParentEntity()

    if (parent == null) {
      return null
    }

    const parentAbsoluteTransformMatrix = new paper.Matrix(
      getAbsoluteTransformMatrix({
        entity: parent,
        time: playback.time,
      })
    )
    const parentAbsoluteTransformMatrixAtKeyframeTime = new paper.Matrix(
      getAbsoluteTransformMatrix({
        entity: parent,
        time: keyframeTime,
      })
    )

    const transformMatrix = new paper.Matrix(
      getTransformMatrix({
        entity: node,
        time: keyframeTime,
      })
    )
    const point = transformMatrix.transform(
      new paper.Point(anchorPoint.x, anchorPoint.y)
    )

    const vertexPosition = parentAbsoluteTransformMatrix.transform(
      new paper.Point(point.x, point.y)
    )
    const leftTangent = parentAbsoluteTransformMatrix.transform(
      new paper.Point(
        keyframeValue.tx1 - keyframeValue.x + point.x,
        keyframeValue.ty1 - keyframeValue.y + point.y
      )
    )
    const rightTangent = parentAbsoluteTransformMatrix.transform(
      new paper.Point(
        keyframeValue.tx2 - keyframeValue.x + point.x,
        keyframeValue.ty2 - keyframeValue.y + point.y
      )
    )

    const zerifyPosition = React.useCallback(
      (moved: Point2D) => {
        const movedPoint =
          parentAbsoluteTransformMatrixAtKeyframeTime.inverseTransform(
            new paper.Point(moved.x, moved.y)
          )
        const startPoint =
          parentAbsoluteTransformMatrixAtKeyframeTime.inverseTransform(
            new paper.Point(0, 0)
          )

        return {
          x: bakedPosition.current.x + movedPoint.x - startPoint.x,
          y: bakedPosition.current.y + movedPoint.y - startPoint.y,
        }
      },
      [parentAbsoluteTransformMatrixAtKeyframeTime, vertexPosition, viewport]
    )

    const hasActiveKeyModificators =
      session.keyModificators.includes(KeyModificator.Ctrl) ||
      session.keyModificators.includes(KeyModificator.Shift)

    const handleVertexSelect = (): void => {
      if (isSelected) {
        if (hasActiveKeyModificators) {
          selection.deselect([keyframe.id])
          return
        }

        return
      }

      if (hasActiveKeyModificators) {
        selection.select([keyframe.id])
        return
      }

      selection.replace([keyframe.id])
    }

    const handleVertexClick = () => {
      if (session.keyModificators.includes(KeyModificator.Ctrl)) {
        if (selection.isSelected(keyframe.id) === false) {
          selection.replace([keyframe.id])
        }
        toggleVertexTangents()
        return
      }

      handleVertexSelect()
    }

    const handleMouseOver = () => {
      const nextActiveTool = session.keyModificators.includes(
        KeyModificator.Ctrl
      )
        ? Tool.ToggleTangents
        : Tool.Selection

      if (prevTool === nextActiveTool) {
        return
      }

      prevTool = tools.activeTool
      tools.changeTool(nextActiveTool)
    }

    const handleMouseLeave = () => {
      tools.changeTool(prevTool || Tool.Selection)
    }

    const handleTangentLeftDragStart = (
      e: React.MouseEvent<SVGElement, MouseEvent>
    ) => {
      e.preventDefault()
      e.stopPropagation()

      if (tools.activeTool !== Tool.Selection) {
        return
      }

      startListen(e)
      targetRef.current = 'tangent-left'
      bakedPosition.current = {
        x: keyframeValue.tx1,
        y: keyframeValue.ty1,
      }
    }

    const handleTangentRightDragStart = (
      e: React.MouseEvent<SVGElement, MouseEvent>
    ) => {
      e.preventDefault()
      e.stopPropagation()

      if (tools.activeTool !== Tool.Selection) {
        return
      }

      startListen(e)
      targetRef.current = 'tangent-right'
      bakedPosition.current = {
        x: keyframeValue.tx2,
        y: keyframeValue.ty2,
      }
    }

    const handleVertexDragStart = (
      e: React.MouseEvent<SVGElement, MouseEvent>
    ) => {
      e.preventDefault()
      e.stopPropagation()

      if (tools.activeTool !== Tool.Selection) {
        return
      }

      startListen(e)
      targetRef.current = 'vertex'
      bakedPosition.current = {
        x: keyframeValue.x,
        y: keyframeValue.y,
      }
    }

    const handleDragEnd = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
    }

    const handleClick = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      handleVertexClick()

      if (session.keyModificators.includes(KeyModificator.Alt)) {
        toggleVertexTangents()
      }
    }

    const tangentVertexSize = 6 / viewport.zoom

    const tangents = () => {
      if (
        keyframeValue.x === keyframeValue.tx1 &&
        keyframeValue.y === keyframeValue.ty1 &&
        keyframeValue.x === keyframeValue.tx2 &&
        keyframeValue.y === keyframeValue.ty2
      ) {
        return null
      }

      return [leftTangent, rightTangent].map((point, idx) => (
        <React.Fragment key={idx}>
          <line
            x1={point.x}
            y1={point.y}
            x2={vertexPosition.x}
            y2={vertexPosition.y}
            strokeWidth={1 / viewport.zoom}
            stroke={color}
          />

          <rect
            name="spatial-control"
            x={point.x}
            y={point.y}
            width={tangentVertexSize}
            height={tangentVertexSize}
            transform={`translate(${-tangentVertexSize / 2} ${
              -tangentVertexSize / 2
            }) rotate(45 ${point.x + tangentVertexSize / 2} ${
              point.y + tangentVertexSize / 2
            })`}
            fill="#FFFFFF"
            stroke={color}
            strokeWidth={1 / viewport.zoom}
            onMouseDown={
              idx === 0
                ? handleTangentLeftDragStart
                : handleTangentRightDragStart
            }
            onMouseUp={handleDragEnd}
            onClick={handleClick}
          />
        </React.Fragment>
      ))
    }

    return (
      <>
        {/* @NOTE: tangents */}
        {tangents()}

        {/* @NOTE: vertex */}
        <circle
          id={keyframe.id}
          cx={vertexPosition.x}
          cy={vertexPosition.y}
          r={4 / viewport.zoom}
          fill="#FFFFFF"
          stroke={color}
          strokeWidth={isSelected ? 3 / viewport.zoom : 1 / viewport.zoom}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          onMouseDown={handleVertexDragStart}
          onMouseUp={handleDragEnd}
          onClick={handleClick}
        />
      </>
    )
  }
)
