import {
  ChildrenExpandedComponent,
  ChildrenRelationsAspect,
  ClipboardType,
  Entity,
  EntityType,
  EntityTypeComponent,
  EntryComponent,
  FpsComponent,
  LockedComponent,
  NumberKeyframe,
  OpacityComponent,
  ParentRelationAspect,
  Point2dKeyframe,
  Point2dValueComponent,
  PositionComponent,
  Project,
  PropertiesExpandedComponent,
  Root,
  RotationComponent,
  ScaleComponent,
  SpatialPoint2dKeyframe,
  SpatialPoint2dValueComponent,
  TargetRelationAspect,
  TimeComponent,
  ValueType,
  clone,
  commitUndo,
  deleteNode,
  expandProperties,
  getClipboardData,
  getEntryOrThrow,
  getKeyframeValueType,
  getNode,
  moveNodes,
  segmentsFromKeyframes,
  setValueSpatialPoint2d,
} from '@aninix-inc/model'
import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { useDebounce, useThrottle } from '@aninix/app-design-system'
import { HotkeyCombination } from '@aninix/app-design-system/components/common/hotkey-combination'
import {
  KeyModificator,
  Playback,
  Session,
  Settings,
  Timeline,
  Tool,
  Tools,
  Viewport,
  featureFlags,
  getGroupFromKeyframe,
  getSelection,
  useCases,
  usePathEditor,
} from '@aninix/core'
import {
  getVectorNode,
  removePathItems,
  selectAllPathPoints,
} from '@aninix/core/modules/common/renderers/svg-path-editor/utils'
import { svgToAni } from '@aninix/core/use-cases'
import { useSelection } from '@aninix/editor/hooks/use-selection'
import { useUndoRedo } from '@aninix/editor/hooks/use-undo-redo'
import { useUpdates } from '@aninix/editor/hooks/use-updates'
import { useLogger } from '@aninix/logger'
import { useNotifications } from '@aninix/notifications'
import { toast } from 'apps/web-app/src/modules/toasts'
import * as R from 'ramda'
import * as React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { hotkeysLabels } from '../../defaults'
import { toggleKeyframe } from '../../hooks/keyframe-indicator'
import { ISegmentsPasteUseCase, ISegmentsReverseUseCase } from '../../use-cases'
import { usePostProjectHistoryVersion } from '../../use-cases/use-post-project-version'
import { createMapFromClipboard } from './commands'
import { getTimeUnit } from './get-time-unit'

export interface IHotkeysInteractor {}

type Payload = {
  session: Session
  playback: Playback
  timeline: Timeline
  tools: Tools
  viewport: Viewport
  project: Project
  user: Settings
  segmentsPasteUseCase: ISegmentsPasteUseCase
  segmentsReverseUseCase: ISegmentsReverseUseCase
}

/**
 * @todo refactor copy-paste events
 */
export const useHotkeysInteractor = ({
  session,
  playback,
  timeline,
  tools,
  viewport,
  project,
  user,
  segmentsPasteUseCase,
  segmentsReverseUseCase,
}: Payload): IHotkeysInteractor => {
  const analytics = useAnalytics()
  const notifications = useNotifications()
  const logger = useLogger()
  const undoRedo = useUndoRedo()
  const selection = useSelection()
  const updates = useUpdates()
  const pathEditor = usePathEditor()

  const { result: createNewVersionResult, createNewVersion } =
    usePostProjectHistoryVersion({
      projectId: project.id,
    })

  // @NOTE: send keypress with timeouts
  const trackKeyPress = useThrottle({
    callback: React.useCallback(
      (hotkey: string) => {
        analytics.track({
          eventName: AnalyticsEvent.HotkeyPressed,
          properties: {
            hotkey,
          },
        })
      },
      [analytics]
    ),
    delay: 30000,
  })

  const debouncedCommitUndo = useDebounce({
    callback: commitUndo,
    delay: 1000,
  })

  useCases.usePlaybackHotkeys({
    tools,
    playback,
    project,
    viewport,
    onKeyPress: trackKeyPress,
  })
  useCases.useViewportHotkeys({
    project,
    viewport,
    onKeyPress: trackKeyPress,
  })

  // @NOTE: remove key modificators on window blur
  React.useEffect(() => {
    const blurHandler = () => {
      session.keyUp(KeyModificator.Shift)
      session.keyUp(KeyModificator.Alt)
      session.keyUp(KeyModificator.Ctrl)
    }

    window.addEventListener('blur', blurHandler)

    return () => {
      window.removeEventListener('blur', blurHandler)
    }
  }, [])

  // @NOTE: modificators keydown
  useHotkeys(
    '*',
    (e) => {
      if (e.key === 'Shift') {
        session.keyDown(KeyModificator.Shift)
      }

      if (e.key === 'Meta' || e.key === 'Control') {
        session.keyDown(KeyModificator.Ctrl)
      }

      if (e.key === 'Alt') {
        session.keyDown(KeyModificator.Alt)
      }
    },
    { keydown: true }
  )

  // @NOTE: modificators keyup
  useHotkeys(
    '*',
    (e) => {
      if (e.key === 'Shift') {
        e.preventDefault()
        e.stopPropagation()
        session.keyUp(KeyModificator.Shift)
      }

      if (e.key === 'Meta' || e.key === 'Control') {
        e.preventDefault()
        e.stopPropagation()
        session.keyUp(KeyModificator.Ctrl)
      }

      if (e.key === 'Alt') {
        e.preventDefault()
        e.stopPropagation()
        session.keyUp(KeyModificator.Alt)
      }
    },
    { keyup: true }
  )

  // @NOTE: delete
  useHotkeys('delete,backspace', (e) => {
    e.preventDefault()
    e.stopPropagation()

    trackKeyPress('delete,backspace')

    if (tools.activeTool === Tool.Pen && featureFlags.editPath) {
      const vectorNode = getVectorNode(
        selection.getEntitiesByEntityType(EntityType.Node),
        session.buffer
      )
      if (!vectorNode) return

      if (pathEditor.isSelectionEmpty()) return
      const pathEditorSelection = pathEditor.getSelection()

      removePathItems(vectorNode, pathEditorSelection, () =>
        updates.batch(() => {
          selection.deselect([vectorNode.id])
          deleteNode(vectorNode)
          tools.changeTool(tools.prevTool)
        })
      )
      pathEditor.clear()
      undoRedo.commitUndo()

      return
    }

    if (selection.isEmpty()) {
      return
    }

    const selectedKeyframes = getSelection(project, EntityType.Keyframe)
    const selectedNodes = getSelection(project, EntityType.Node)

    updates.batch(() => {
      selection.deselectAll()
      let nodeIdsToSelect: string[] = []
      for (const keyframe of selectedKeyframes) {
        const node = getNode(keyframe)
        if (node != null) {
          nodeIdsToSelect.push(node.id)
        }
        keyframe.getProjectOrThrow().removeEntity(keyframe.id)
      }
      if (featureFlags.shapesCreation) {
        for (const node of selectedNodes) {
          // @NOTE: ignore entry, related to ANI-2033
          if (node.hasComponent(EntryComponent)) {
            continue
          }
          deleteNode(node)
        }
      }
      selection.replace(nodeIdsToSelect)
    })
    commitUndo(project)
  })

  // @NOTE: zoom in timeline
  useHotkeys('ctrl+=,meta+=', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('ctrl+=,meta+=')
    timeline.zoomBy(1.25)
  })

  // @NOTE: zoom in timeline
  useHotkeys('ctrl+-,meta+-', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('ctrl+-,meta+-')
    timeline.zoomBy(0.75)
  })

  // @NOTE: show animated properties
  useHotkeys('u', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('u')

    updates.batch(() => {
      project.entities.forEach((node) => {
        if (node.hasComponent(ChildrenExpandedComponent)) {
          node.updateComponent(ChildrenExpandedComponent, true)
        }
        if (node.hasComponent(PropertiesExpandedComponent)) {
          node.updateComponent(PropertiesExpandedComponent, true)
        }
      })
    })
    commitUndo(project)
  })

  // @NOTE: add/remove keyframes on position
  useHotkeys('p', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('p')
    updates.batch(() => {
      const keyframesOrNodes = toggleKeyframe(
        SpatialPoint2dKeyframe,
        selection
          .getEntities()
          .map((e) => getNode(e))
          .filter((e) => e != null)
          .map((e) => e.getComponentOrThrow(PositionComponent)),
        playback.time
      )

      for (const keyframesOrNode of keyframesOrNodes) {
        const entityType =
          keyframesOrNode.getComponentOrThrow(EntityTypeComponent).value

        if (entityType === EntityType.Keyframe) {
          const node = getNode(keyframesOrNode)
          if (node == null) {
            continue
          }
          expandProperties(node)
          continue
        }
      }
    })
    commitUndo(project)
  })

  // @NOTE: add/remove keyframe on rotation
  useHotkeys('r', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('r')
    updates.batch(() => {
      const keyframesOrNodes = toggleKeyframe(
        NumberKeyframe,
        selection
          .getEntities()
          .map((e) => getNode(e))
          .filter((e) => e != null)
          .map((e) => e.getComponentOrThrow(RotationComponent)),
        playback.time
      )

      for (const keyframesOrNode of keyframesOrNodes) {
        const entityType =
          keyframesOrNode.getComponentOrThrow(EntityTypeComponent).value

        if (entityType === EntityType.Keyframe) {
          const node = getNode(keyframesOrNode)
          if (node == null) {
            continue
          }
          expandProperties(node)
          continue
        }
      }
    })
    commitUndo(project)
  })

  // @NOTE: add/remove keyframe on scale
  useHotkeys('s', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('s')
    updates.batch(() => {
      const keyframesOrNodes = toggleKeyframe(
        Point2dKeyframe,
        selection
          .getEntities()
          .map((e) => getNode(e))
          .filter((e) => e != null)
          .map((e) => e.getComponentOrThrow(ScaleComponent)),
        playback.time
      )

      for (const keyframesOrNode of keyframesOrNodes) {
        const entityType =
          keyframesOrNode.getComponentOrThrow(EntityTypeComponent).value

        if (entityType === EntityType.Keyframe) {
          const node = getNode(keyframesOrNode)
          if (node == null) {
            continue
          }
          expandProperties(node)
          continue
        }
      }
    })
    commitUndo(project)
  })

  // @NOTE: add/remove keyframe on opacity
  useHotkeys('o', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('o')
    updates.batch(() => {
      const keyframesOrNodes = toggleKeyframe(
        NumberKeyframe,
        selection
          .getEntities()
          .map((e) => getNode(e))
          .filter((e) => e != null)
          .map((e) => e.getComponentOrThrow(OpacityComponent)),
        playback.time
      )

      for (const keyframesOrNode of keyframesOrNodes) {
        const entityType =
          keyframesOrNode.getComponentOrThrow(EntityTypeComponent).value

        if (entityType === EntityType.Keyframe) {
          const node = getNode(keyframesOrNode)
          if (node == null) {
            continue
          }
          expandProperties(node)
          continue
        }
      }
    })
    commitUndo(project)
  })

  // @NOTE: update start of preview range
  useHotkeys('b', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('b')

    const duration = playback.previewRange.end - playback.time
    playback
      .updatePreviewRangeStart(playback.time)
      .updatePreviewRangeDuration(duration)
  })

  // @NOTE: update end of preview range
  useHotkeys('n', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('n')

    playback.updatePreviewRangeEnd(playback.time)
  })

  // @NOTE: select all
  useHotkeys('ctrl+a,meta+a', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('ctrl+a,meta+a')

    if (tools.activeTool === Tool.Pen && featureFlags.editPath) {
      const vectorNode = getVectorNode(
        selection.getEntitiesByEntityType(EntityType.Node),
        session.buffer
      )
      if (!vectorNode) return
      selectAllPathPoints(vectorNode, pathEditor)
      return
    }

    const firstLevelOfNodes = project.getEntitiesByPredicate(
      (entity) =>
        entity
          .getAspect(ParentRelationAspect)
          ?.getParentEntity()
          ?.hasComponent(EntryComponent) ?? false
    )
    selection.select(firstLevelOfNodes.map((n) => n.id))
    commitUndo(project)

    const entry = getEntryOrThrow(project)
    entry.updateComponent(ChildrenExpandedComponent, true)
  })

  // @NOTE: deselect all
  useHotkeys('ctrl+shift+a,meta+shift+a', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('ctrl+shift+a,meta+shift+a')
    selection.deselectAll()
  })

  // @NOTE: undo
  useHotkeys('ctrl+z,meta+z', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('ctrl+z,meta+z')

    if (undoRedo.canUndo()) {
      if (playback.isPlaying) {
        playback.pause()
      }

      // @NOTE: required to deselect selected keyframes
      // const keyframePatches = undoManager.nextUndoCommit.filter(
      //   (patch) =>
      //     patch.value?.modelType === 'keyframe' &&
      //     (patch.op === 'add' || patch.op === 'remove')
      // )
      // if (keyframePatches.length > 0) {
      //   keyframePatches.forEach((keyframe) =>
      //     session.deselectKeyframe(genericKeyframe.create(toJS(keyframe.value)))
      //   )
      // }

      // @NOTE: required to commit undo before undoing if there any tempGroups.
      // May be happen when not all changes properly commited.
      // undoRedo.commitUndo()
      updates.batch(() => {
        undoRedo.undo()
      })
    }
  })

  // @NOTE: redo
  useHotkeys('ctrl+shift+z,meta+shift+z', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('ctrl+shift+z,meta+shift+z')

    if (undoRedo.canRedo()) {
      if (playback.isPlaying) {
        playback.pause()
      }

      // @NOTE: required to deselect selected keyframes
      // const keyframePatches = undoManager.nextRedoCommit.filter(
      //   (patch) =>
      //     patch.value?.modelType === 'keyframe' &&
      //     (patch.op === 'add' || patch.op === 'remove')
      // )
      // if (keyframePatches.length > 0) {
      //   keyframePatches.forEach((keyframe) =>
      //     session.deselectKeyframe(genericKeyframe.create(toJS(keyframe.value)))
      //   )
      // }

      updates.batch(() => {
        undoRedo.redo()
      })
    }
  })

  // @NOTE: cut
  React.useEffect(() => {
    const listener = (e: ClipboardEvent) => {
      trackKeyPress('ctrl+x,meta+x')

      // @NOTE: required to skip event when input selected
      if (
        document.activeElement?.nodeName === 'INPUT' ||
        document.activeElement?.nodeName === 'TEXTAREA'
      ) {
        return
      }

      const result = getClipboardData(
        getSelection(project, EntityType.Keyframe)
      )

      if (result == null) {
        notifications.showNotification('Nothing to cut', {
          autoClose: 1000,
        })
        return
      }

      const stringifiedData = JSON.stringify(result)
      e.clipboardData?.setData('application/json', stringifiedData)
      logger.log('Cut attempt, data:', stringifiedData)
      const selectedKeyframes = getSelection(project, EntityType.Keyframe)
      selection.deselectAll()
      selectedKeyframes.forEach((keyframe) => project.removeEntity(keyframe.id))
      e.preventDefault()
      return
    }

    document.addEventListener('cut', listener)

    return () => {
      document.removeEventListener('cut', listener)
    }
  }, [])

  // @NOTE: copy
  React.useEffect(() => {
    const listener = (e: ClipboardEvent) => {
      const target = e.target as any

      trackKeyPress('ctrl+c,meta+c')

      if (target?.id === 'clipboard-service') {
        const data = target!.value

        try {
          JSON.parse(data)
          e.clipboardData?.setData('application/json', data)
          e.preventDefault()
        } catch (err: any) {
          e.clipboardData?.setData('text/plain', data)
          e.preventDefault()
        }
        return
      }

      // @NOTE: required to skip event when input selected
      if (target?.nodeName === 'INPUT' || target?.nodeName === 'TEXTAREA') {
        return
      }

      const result = getClipboardData(
        getSelection(project, EntityType.Keyframe)
      )

      if (result == null) {
        notifications.showNotification('Nothing to copy', {
          autoClose: 1000,
        })
        return
      }

      const stringifiedData = JSON.stringify(result)
      e.clipboardData?.setData('application/json', stringifiedData)
      logger.log('Copy attempt, data:', stringifiedData)
      e.preventDefault()
      return
    }

    document.addEventListener('copy', listener)

    return () => {
      document.removeEventListener('copy', listener)
    }
  }, [])

  // @NOTE: paste
  React.useEffect(() => {
    const mapFromClipboard = createMapFromClipboard()

    const listener = (e: ClipboardEvent) => {
      const target = e.target as any

      trackKeyPress('ctrl+v,meta+v')

      // @NOTE: required to skip event when input selected
      if (target?.nodeName === 'INPUT' || target?.nodeName === 'TEXTAREA') {
        return
      }

      // @TODO: remove from here because meta+shift+v is another command on mac's and windows machines
      const shouldReverse = session.keyModificators.includes(
        KeyModificator.Shift
      )

      // @NOTE: turnaround required when we use navigator to copy texts

      const isJson = e.clipboardData?.types.includes('application/json')

      const rawData = e.clipboardData?.getData(
        isJson ? 'application/json' : 'text/plain'
      )

      logger.log(`Paste attempt, data:`, rawData)

      if (isJson) {
        const jsonData = mapFromClipboard(rawData)

        if (!jsonData) {
          notifications.showNotification('Nothing to paste', {
            autoClose: 1000,
          })

          return
        }

        updates.batch(() => {
          if (jsonData.type === ClipboardType.Keyframes) {
            try {
              segmentsPasteUseCase.execute({
                keyframes: jsonData.value,
                time: playback.time,
              })
            } catch (err) {
              notifications.showNotification(
                <>Please select any layer and paste again</>,
                {
                  variant: 'regular',
                  id: 'selected-layers-required-to-paste',
                }
              )
            }
          }

          if (shouldReverse) {
            segmentsReverseUseCase.execute({
              segments: segmentsFromKeyframes(
                getSelection(project, EntityType.Keyframe)
              ),
            })
          }
        })
      }

      const isSvg = rawData?.includes('<svg') && featureFlags.importSvg

      if (rawData && isSvg) {
        const start = rawData.indexOf('<svg')
        const end = rawData.indexOf('</svg>') + 6
        const svg = rawData.slice(start, end)

        let isSuccess = false
        try {
          updates.batch(() => {
            const svgRoot = svgToAni(svg, project)
            const selectedEntity = selection
              .getEntitiesByEntityType(EntityType.Node)
              .at(-1)
            const selectedParent = selectedEntity
              ?.getAspect(ParentRelationAspect)
              ?.getParentEntity()
            if (selectedEntity && selectedParent) {
              moveNodes([svgRoot], selectedParent, { before: selectedEntity })
            }
            selection.deselectAll()
            selection.select([svgRoot.id])
          })
          isSuccess = true
        } catch (e) {
          notifications.showNotification(
            'An error occurred while pasting the SVG element.',
            { autoClose: 1000 }
          )
        } finally {
          commitUndo(project)
          if (!isSuccess) updates.batch(() => undoRedo.undo())
        }
      }

      // @NOTE: required to enable trim path
      // const includesTrimPath = R.any(
      //   (keyframe: any) =>
      //     [
      //       PropertyType.TrimStart,
      //       PropertyType.TrimEnd,
      //       PropertyType.TrimOffset,
      //     ].includes(keyframe.propertyType),
      //   data.value
      // )
      // if (includesTrimPath) {
      //   getSelection(project,EntityType.Node).forEach((node) => {
      //     if (
      //       node.hasAspect(TrimPathAspect) &&
      //       node.getAspect(TrimPathAspect).isEnabled === false
      //     ) {
      //       node.getAspect(TrimPathAspect).enable()
      //     }
      //   })
      // }
    }

    document.addEventListener('paste', listener)

    return () => {
      document.removeEventListener('paste', listener)
    }
  }, [notifications])

  // @NOTE: move up
  useHotkeys('shift+up,up', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('shift+up,up')

    const offset = session.keyModificators.includes(KeyModificator.Shift)
      ? 10
      : 1

    updates.batch(() => {
      const selectedNodes = getSelection(project, EntityType.Node)
      const selectedKeyframes = getSelection(project, EntityType.Keyframe)

      for (const keyframe of selectedKeyframes) {
        if (getKeyframeValueType(keyframe) !== ValueType.SpatialPoint2d) {
          continue
        }

        setValueSpatialPoint2d(
          keyframe.getComponentOrThrow(SpatialPoint2dValueComponent),
          (value) => ({
            x: value.x,
            y: value.y - offset,
            tx1: value.tx1,
            ty1: value.ty1 - offset,
            tx2: value.tx2,
            ty2: value.ty2 - offset,
          }),
          playback.time
        )
      }

      for (const node of selectedNodes) {
        setValueSpatialPoint2d(
          node.getComponentOrThrow(PositionComponent),
          (value) => ({
            x: value.x,
            y: value.y - offset,
            tx1: value.tx1,
            ty1: value.ty1 - offset,
            tx2: value.tx2,
            ty2: value.ty2 - offset,
          }),
          playback.time
        )
      }
    })
    debouncedCommitUndo(project)
  })

  // @NOTE: move right
  useHotkeys('shift+right,right', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('shift+right,right')

    const offset = session.keyModificators.includes(KeyModificator.Shift)
      ? 10
      : 1

    updates.batch(() => {
      const selectedNodes = getSelection(project, EntityType.Node)
      const selectedKeyframes = getSelection(project, EntityType.Keyframe)

      for (const keyframe of selectedKeyframes) {
        if (getKeyframeValueType(keyframe) !== ValueType.SpatialPoint2d) {
          continue
        }

        setValueSpatialPoint2d(
          keyframe.getComponentOrThrow(SpatialPoint2dValueComponent),
          (value) => ({
            x: value.x + offset,
            y: value.y,
            tx1: value.tx1 + offset,
            ty1: value.ty1,
            tx2: value.tx2 + offset,
            ty2: value.ty2,
          }),
          playback.time
        )
      }

      for (const node of selectedNodes) {
        setValueSpatialPoint2d(
          node.getComponentOrThrow(PositionComponent),
          (value) => ({
            x: value.x + offset,
            y: value.y,
            tx1: value.tx1 + offset,
            ty1: value.ty1,
            tx2: value.tx2 + offset,
            ty2: value.ty2,
          }),
          playback.time
        )
      }
    })
    debouncedCommitUndo(project)
  })

  // @NOTE: move down
  useHotkeys('shift+down,down', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('shift+down,down')

    const offset = session.keyModificators.includes(KeyModificator.Shift)
      ? 10
      : 1

    updates.batch(() => {
      const selectedNodes = getSelection(project, EntityType.Node)
      const selectedKeyframes = getSelection(project, EntityType.Keyframe)

      for (const keyframe of selectedKeyframes) {
        if (getKeyframeValueType(keyframe) !== ValueType.SpatialPoint2d) {
          continue
        }

        setValueSpatialPoint2d(
          keyframe.getComponentOrThrow(SpatialPoint2dValueComponent),
          (value) => ({
            x: value.x,
            y: value.y + offset,
            tx1: value.tx1,
            ty1: value.ty1 + offset,
            tx2: value.tx2,
            ty2: value.ty2 + offset,
          }),
          playback.time
        )
      }

      for (const node of selectedNodes) {
        setValueSpatialPoint2d(
          node.getComponentOrThrow(PositionComponent),
          (value) => ({
            x: value.x,
            y: value.y + offset,
            tx1: value.tx1,
            ty1: value.ty1 + offset,
            tx2: value.tx2,
            ty2: value.ty2 + offset,
          }),
          playback.time
        )
      }
    })
    debouncedCommitUndo(project)
  })

  // @NOTE: move left
  useHotkeys('shift+left,left', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('shift+left,left')

    const offset = session.keyModificators.includes(KeyModificator.Shift)
      ? 10
      : 1

    updates.batch(() => {
      const selectedNodes = getSelection(project, EntityType.Node)
      const selectedKeyframes = getSelection(project, EntityType.Keyframe)

      for (const keyframe of selectedKeyframes) {
        if (getKeyframeValueType(keyframe) !== ValueType.SpatialPoint2d) {
          continue
        }

        setValueSpatialPoint2d(
          keyframe.getComponentOrThrow(SpatialPoint2dValueComponent),
          (value) => ({
            x: value.x - offset,
            y: value.y,
            tx1: value.tx1 - offset,
            ty1: value.ty1,
            tx2: value.tx2 - offset,
            ty2: value.ty2,
          }),
          playback.time
        )
      }

      for (const node of selectedNodes) {
        setValueSpatialPoint2d(
          node.getComponentOrThrow(PositionComponent),
          (value) => ({
            x: value.x - offset,
            y: value.y,
            tx1: value.tx1 - offset,
            ty1: value.ty1,
            tx2: value.tx2 - offset,
            ty2: value.ty2,
          }),
          playback.time
        )
      }
    })
    debouncedCommitUndo(project)
  })

  // @NOTE: move keyframes right
  useHotkeys('shift+alt+right,alt+right', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('shift+alt+right,alt+right')

    const offset = session.keyModificators.includes(KeyModificator.Shift)
      ? 10
      : 1

    const root = project.getEntityByTypeOrThrow(Root)
    const timeUnit = getTimeUnit({
      timeFormat: user.timeFormat,
      fps: root.getComponentOrThrow(FpsComponent).value,
    })
    getSelection(project, EntityType.Keyframe).forEach((item) => {
      item.updateComponent(TimeComponent, (v) => v + timeUnit * offset)
    })
    debouncedCommitUndo(project)
  })

  // @NOTE: move keyframes left
  useHotkeys('shift+alt+left,alt+left', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('shift+alt+left,alt+left')

    const offset = session.keyModificators.includes(KeyModificator.Shift)
      ? 10
      : 1

    const root = project.getEntityByTypeOrThrow(Root)
    const timeUnit = getTimeUnit({
      timeFormat: user.timeFormat,
      fps: root.getComponentOrThrow(FpsComponent).value,
    })
    getSelection(project, EntityType.Keyframe).forEach((item) => {
      item.updateComponent(TimeComponent, (v) => v - timeUnit * offset)
    })
    debouncedCommitUndo(project)
  })

  // @NOTE: select hand tool
  useHotkeys('h', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('h')

    tools.changeTool(Tool.Hand)
  })

  useHotkeys('c', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('c')

    tools.changeTool(Tool.Comments)
    selection.deselectAll()
  })

  // @NOTE: select selection tool
  useHotkeys('v', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('v')

    tools.changeTool(Tool.Selection)
  })

  // @NOTE: save project
  useHotkeys('ctrl+s,meta+s', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('ctrl+s,meta+s')

    notifications.showNotification(
      <p>
        Aninix saves project automatically. Hit{' '}
        <HotkeyCombination
          keys={[hotkeysLabels().ctrl, hotkeysLabels().option, 'S']}
        />{' '}
        to create new version
      </p>,
      {
        id: 'saves-automatically',
      }
    )
  })

  const isNewVersionCreating = React.useRef(false)
  // @NOTE: create new version
  useHotkeys('ctrl+alt+s,meta+alt+s', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('ctrl+alt+s,meta+alt+s')

    if (isNewVersionCreating.current) {
      return
    }

    isNewVersionCreating.current = true
    notifications.showNotification('Creating new version. Please wait...', {
      id: 'version-created',
    })
    createNewVersion(project).then(() => {
      isNewVersionCreating.current = false
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    })
  })
  React.useEffect(() => {
    if (createNewVersionResult.isLoading) {
      return
    }

    if (createNewVersionResult.isError) {
      notifications.showNotification("Couldn't create project version", {
        variant: 'error',
      })
    }
  }, [createNewVersionResult])

  // @NOTE: select children
  useHotkeys('enter', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('enter')

    const selectedNodes = getSelection(project, EntityType.Node)
    const vectorNode = getVectorNode(selectedNodes, session.buffer)

    if (vectorNode && featureFlags.editPath) {
      if (tools.activeTool === Tool.Pen) {
        tools.changeTool(tools.prevTool)
        return
      } else {
        tools.changeTool(Tool.Pen)
        selection.replace([vectorNode.id])
        return
      }
    }

    selectedNodes.forEach((node) => {
      if (node.hasComponent(ChildrenExpandedComponent) === false) {
        return
      }

      selection.deselect([node.id])
      node.updateComponent(ChildrenExpandedComponent, true)
      if (node.hasAspect(ChildrenRelationsAspect) !== false) {
        selection.select(
          node
            .getAspectOrThrow(ChildrenRelationsAspect)
            .getChildrenList()
            .map((n) => n.id)
        )
        commitUndo(project)
      }
    })
  })

  useHotkeys('ctrl+enter,meta+enter', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('ctrl+enter,meta+enter')

    const keyframeIdsToSelect = getSelection(project, EntityType.Node).flatMap(
      (node) => {
        const keyframesToSelect = project.getEntitiesByPredicate(
          (entity) =>
            entity.getAspect(TargetRelationAspect)?.getTargetEntity()?.id ===
            node.id
        )

        if (keyframesToSelect.length === 0) {
          return []
        }

        return keyframesToSelect.map((keyframe) => keyframe.id)
      }
    )
    selection.replace(keyframeIdsToSelect)
    commitUndo(project)
  })

  // @NOTE: select parent
  useHotkeys('shift+enter', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('shift+enter')

    const selectedNodes = getSelection(project, EntityType.Node)
    const selectedKeyframes = getSelection(project, EntityType.Keyframe)

    if (selectedNodes.length === 0 && selectedKeyframes.length === 0) {
      return
    }

    // @NOTE: selet parent of keyframes
    if (selectedKeyframes.length > 0) {
      const parentsToSelect = selectedKeyframes
        .map((k) => getGroupFromKeyframe(k).layer)
        .filter((l) => l != null)
      const uniqParents = R.uniqBy((node) => node.id, parentsToSelect)

      if (uniqParents.length === 0) {
        return
      }

      selection.replace(uniqParents.map((n) => n.id))
      commitUndo(project)
      return
    }

    // @NOTE: select parent of nodes
    const parentsToSelect = selectedNodes
      .filter((n) => n.hasAspect(ParentRelationAspect))
      .map((n) =>
        n.getAspectOrThrow(ParentRelationAspect).getParentEntityOrThrow()
      )
    const uniqParents = R.uniqBy((node) => node.id, parentsToSelect)

    if (uniqParents.length === 0) {
      return
    }

    selection.replace(uniqParents.map((n) => n.id))
    commitUndo(project)
  })

  // @NOTE: deselect all
  useHotkeys('esc', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('esc')
    selection.deselectAll()
  })

  // @NOTE: toggle keyframe at time slider
  // @TODO: implement
  // useHotkeys('x', (e) => {
  //   e.preventDefault()
  //   e.stopPropagation()
  //   const selectedKeyframes = session.getSelectedKeyframes()
  // })

  // @NOTE: lock selected
  useHotkeys('ctrl+l,meta+l', (e) => {
    e.stopPropagation()
    e.preventDefault()
    trackKeyPress('ctrl+l,meta+l')

    const selectedNodes = getSelection(project, EntityType.Node)

    if (selectedNodes.length === 0) {
      return
    }

    selectedNodes.forEach((node) => node.updateComponent(LockedComponent, true))
    selection.deselectAll()
    commitUndo(project)
  })

  // @NOTE: unlock all
  useHotkeys('shift+ctrl+l,shift+meta+l', (e) => {
    e.stopPropagation()
    e.preventDefault()
    trackKeyPress('shift+ctrl+l,shift+meta+l')

    project.entities.forEach((entity) => {
      if (entity.hasComponent(LockedComponent)) {
        entity.updateComponent(LockedComponent, false)
      }
    })
    commitUndo(project)
  })

  // @NOTE: align segments to selection
  useHotkeys('[', (e) => {
    e.stopPropagation()
    e.preventDefault()
    trackKeyPress('[')

    const keyframes = R.sortBy(
      (e) => e.getComponentOrThrow(TimeComponent).value,
      getSelection(project, EntityType.Keyframe)
    )

    if (keyframes.length === 0) {
      return
    }

    const time = playback.time
    const targetTime =
      R.head(keyframes)!.getComponentOrThrow(TimeComponent).value
    const delta = time - targetTime
    keyframes.forEach((keyframe) => {
      keyframe.updateComponent(TimeComponent, (v) => v + delta)
    })
    commitUndo(project)
  })

  useHotkeys(']', (e) => {
    e.stopPropagation()
    e.preventDefault()
    trackKeyPress(']')

    const keyframes = R.sortBy(
      (e) => e.getComponentOrThrow(TimeComponent).value,
      getSelection(project, EntityType.Keyframe)
    )

    if (keyframes.length === 0) {
      return
    }

    const time = playback.time
    const targetTime =
      R.last(keyframes)!.getComponentOrThrow(TimeComponent).value
    const delta = time - targetTime
    keyframes.forEach((keyframe) => {
      keyframe.updateComponent(TimeComponent, (v) => v + delta)
    })
    commitUndo(project)
  })

  // @NOTE: create line of keyframes
  useHotkeys('l', (e) => {
    e.stopPropagation()
    e.preventDefault()
    trackKeyPress('l')

    const keyframes = getSelection(project, EntityType.Keyframe)

    if (keyframes.length === 0) {
      return
    }

    const groupedProperties = R.groupBy(
      (k) =>
        k.getAspectOrThrow(TargetRelationAspect).getTargetComponentOrThrow().id,
      keyframes
    )
    let newKeyframes: Entity[] = []
    R.values(groupedProperties).forEach((group) => {
      const keyframeAtTime = group!.find(
        (k) => k.getComponentOrThrow(TimeComponent).value === playback.time
      )
      if (keyframeAtTime != null) {
        return
      }

      // @TODO: create keyframes by type
      // const keyframe = property.createKeyframe(playback.time)
      // newKeyframes.push(keyframe)
    })

    /* eslint-disable-next-line sonarjs/no-empty-collection */
    selection.replace(newKeyframes.map((k) => k.id))
    commitUndo(project)
  })

  // @NOTE: start edit keyframes
  useHotkeys('e', (e) => {
    e.stopPropagation()
    e.preventDefault()
    trackKeyPress('e')
    const keyframes = getSelection(project, EntityType.Keyframe)
    // @TODO: make it work. Probably ID of the input field were changed
    document.getElementById(keyframes.map((k) => k.id).join('-'))?.focus()
  })

  useHotkeys('shift+h', (e) => {
    e.stopPropagation()
    e.preventDefault()
    trackKeyPress('shift+h')
    const selectedNodes = getSelection(project, EntityType.Node)
    const scaleKeyframes = getSelection(project, EntityType.Keyframe).filter(
      (keyframe) =>
        keyframe
          .getAspectOrThrow(TargetRelationAspect)
          .getTargetComponentOrThrow() instanceof ScaleComponent
    )

    if (selectedNodes.length === 0 && scaleKeyframes.length === 0) {
      toast('Nothing to flip, select at least 1 layer or keyframe')
      return
    }

    if (scaleKeyframes.length > 0) {
      scaleKeyframes.forEach((keyframe) => {
        keyframe.updateComponent(Point2dValueComponent, (v) => ({
          x: -v.x,
          y: v.y,
        }))
      })
    } else {
      selectedNodes.forEach((node) => {
        node.updateComponent(ScaleComponent, (v) => ({
          x: -v.x,
          y: v.y,
        }))
      })
    }
    commitUndo(project)
  })

  useHotkeys('shift+v', (e) => {
    e.stopPropagation()
    e.preventDefault()
    trackKeyPress('shift+v')
    const selectedNodes = getSelection(project, EntityType.Node)
    const scaleKeyframes = getSelection(project, EntityType.Keyframe).filter(
      (keyframe) =>
        keyframe
          .getAspectOrThrow(TargetRelationAspect)
          .getTargetComponentOrThrow() instanceof ScaleComponent
    )

    if (selectedNodes.length === 0 && scaleKeyframes.length === 0) {
      toast('Nothing to flip, select at least 1 layer or keyframe')
      return
    }

    if (scaleKeyframes.length > 0) {
      scaleKeyframes.forEach((keyframe) => {
        keyframe.updateComponent(Point2dValueComponent, (v) => ({
          x: v.x,
          y: -v.y,
        }))
      })
    } else {
      selectedNodes.forEach((node) => {
        node.updateComponent(ScaleComponent, (v) => ({
          x: v.x,
          y: -v.y,
        }))
      })
    }
    commitUndo(project)
  })

  useHotkeys('ctrl+[,meta+[', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('cmd+[')
    const selectedNodes = getSelection(project, EntityType.Node)
    if (selectedNodes.length === 0) {
      toast('Nothing to move, select at least 1 layer')
      return
    }

    const groupedByParent = R.groupBy(
      (node) =>
        node.getAspectOrThrow(ParentRelationAspect).getParentEntityOrThrow().id,
      selectedNodes
    )

    updates.batch(() => {
      Object.values(groupedByParent).forEach((nodes) => {
        if (!nodes || nodes.length === 0) return
        const parent = nodes[0]
          .getAspectOrThrow(ParentRelationAspect)
          .getParentEntityOrThrow()

        const sortedNodes = R.sortBy(
          (node) =>
            parent
              .getAspectOrThrow(ChildrenRelationsAspect)
              .getIndexOfById(node.id),
          nodes
        )

        const groupedByAdjacency = sortedNodes.reduce<Entity[][]>(
          (acc, node, index) => {
            if (
              index === 0 ||
              parent
                .getAspectOrThrow(ChildrenRelationsAspect)
                .getIndexOfById(node.id) !==
                parent
                  .getAspectOrThrow(ChildrenRelationsAspect)
                  .getIndexOfById(sortedNodes[index - 1].id) +
                  1
            ) {
              acc.push([node])
            } else {
              acc[acc.length - 1].push(node)
            }
            return acc
          },
          []
        )

        groupedByAdjacency.forEach((group) => {
          const previousSibling = parent
            .getAspectOrThrow(ChildrenRelationsAspect)
            .getChildrenList()
            .find((_, index, array) => array[index + 1]?.id === group[0].id)

          if (previousSibling)
            moveNodes(group, parent, { before: previousSibling })
        })
      })
    })
    commitUndo(project)
  })

  useHotkeys('ctrl+],meta+]', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('cmd+]')

    const selectedNodes = getSelection(project, EntityType.Node)
    if (selectedNodes.length === 0) {
      toast('Nothing to move, select at least 1 layer')
      return
    }

    const groupedByParent = R.groupBy(
      (node) =>
        node.getAspectOrThrow(ParentRelationAspect).getParentEntityOrThrow().id,
      selectedNodes
    )

    updates.batch(() => {
      Object.values(groupedByParent).forEach((nodes) => {
        if (!nodes || nodes.length === 0) return
        const parent = nodes[0]
          .getAspectOrThrow(ParentRelationAspect)
          .getParentEntityOrThrow()

        const sortedNodes = R.sortBy(
          (node) =>
            parent
              .getAspectOrThrow(ChildrenRelationsAspect)
              .getIndexOfById(node.id),
          nodes
        )

        const groupedByAdjacency = sortedNodes.reduce<Entity[][]>(
          (acc, node, index) => {
            if (
              index === 0 ||
              parent
                .getAspectOrThrow(ChildrenRelationsAspect)
                .getIndexOfById(node.id) !==
                parent
                  .getAspectOrThrow(ChildrenRelationsAspect)
                  .getIndexOfById(sortedNodes[index - 1].id) +
                  1
            ) {
              acc.push([node])
            } else {
              acc[acc.length - 1].push(node)
            }
            return acc
          },
          []
        )

        groupedByAdjacency.forEach((group) => {
          const nextSibling = parent
            .getAspectOrThrow(ChildrenRelationsAspect)
            .getChildrenList()
            .find(
              (_, index, array) =>
                array[index - 1]?.id === group[group.length - 1].id
            )

          if (nextSibling) moveNodes(group, parent, { after: nextSibling })
        })
      })
    })
    commitUndo(project)
  })

  useHotkeys('ctrl+d,meta+d', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('cmd+d')

    const selectedNodes = getSelection(project, EntityType.Node).filter(
      (n) => !n.hasComponent(EntryComponent)
    )
    if (selectedNodes.length === 0) {
      toast('Nothing to duplicate, select at least 1 layer')
      return
    }

    selectedNodes.map((node) => {
      const nodeParent = node
        .getAspectOrThrow(ParentRelationAspect)
        .getParentEntityOrThrow()

      const newNode = clone(node, project)

      moveNodes([newNode], nodeParent, {
        after: node,
      })
    })

    commitUndo(project)
  })

  return {}
}
