import { makeAutoObservable } from 'mobx'
import * as React from 'react'

export enum Tool {
  Selection = 'selection',
  Frame = 'frame',
  Shape = 'shape',
  Hand = 'hand',
  ToggleTangents = 'toggle-tangents',
  Pen = 'pen',
  Comments = 'comments',
}

export enum ShapeType {
  Rectangle = 'rectangle',
  Line = 'line',
  Ellipse = 'ellipse',
  Polygon = 'polygon',
  Star = 'star',
}

/**
 * @description active tool
 */
export class Tools {
  public prevTool: Tool = Tool.Selection
  public activeTool: Tool = Tool.Selection
  public activeShape: ShapeType = ShapeType.Rectangle

  constructor() {
    makeAutoObservable(this)
  }

  changeTool = (tool: Tool) => {
    if (this.activeTool === tool) return
    this.prevTool = this.activeTool
    this.activeTool = tool
  }

  changeShape = (shape: ShapeType) => {
    this.activeShape = shape
  }
}

const Context = React.createContext<Tools>(null as any)

export const useTools = (): Tools => {
  const context = React.useContext(Context)

  if (context == null) {
    throw new Error(
      'Tools context not found. Use ToolsProvider at the root component.'
    )
  }

  return context
}

const defaultTools = new Tools()
export const ToolsProvider: React.FCC<{ store?: Tools }> = ({
  store = defaultTools,
  children,
}) => <Context.Provider value={store}>{children}</Context.Provider>
