import {
  Entity,
  EntityType,
  getAbsoluteTransformMatrix,
  getAnchorPoint,
  getNode,
  NodeColorComponent,
  Point2D,
  SelectionSystem,
  setAnchorPoint,
} from '@aninix-inc/model'
import { useMouseMove } from '@aninix/app-design-system'
import { featureFlags, nodeColors, useSystem } from '@aninix/core'
import { usePlayback, useViewport } from '@aninix/core/stores'
import { getOutlineBox, OutlineBox } from '@aninix/core/utils'
import { useSelection } from '@aninix/editor/hooks/use-selection'
import { useUndoRedo } from '@aninix/editor/hooks/use-undo-redo'
import { useUpdates } from '@aninix/editor/hooks/use-updates'
import * as paper from 'paper'
import * as React from 'react'
import { Snapping, SnappingItem, snapPointToOutlineBox } from './snapping'

export const SvgAnchorPoint = () => {
  const playback = usePlayback()
  const viewport = useViewport()
  const updates = useUpdates()
  const undoRedo = useUndoRedo()
  const selection = useSelection()
  const nodes = getNodesToRender(selection)
  useSystem(selection)

  const nodeTransfromMatrix = React.useRef<paper.Matrix | null>(null)
  const transformNode = React.useRef<Entity | null>(null)
  const initAnchorPoint = React.useRef<paper.Point | null>(null)
  const nodeOutlineBox = React.useRef<OutlineBox | null>(null)
  const [newAnchorPoint, setNewAnchorPoint] = React.useState<Point2D | null>(
    null
  )
  const [snappingItems, setSnappingItems] = React.useState<SnappingItem[]>([])

  const { startListen, offsetY, offsetX, isListening } = useMouseMove()

  const startAnchorDnd = React.useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>, node: Entity) => {
      e.stopPropagation()
      startListen(e)

      nodeTransfromMatrix.current = new paper.Matrix(
        getAbsoluteTransformMatrix({
          entity: node,
          time: playback.time,
        })
      )
      nodeOutlineBox.current = getOutlineBox(node, playback.time)
      initAnchorPoint.current = new paper.Point(
        getAnchorPoint(node, playback.time)
      )
      transformNode.current = node
    },
    [startListen, playback.time]
  )

  const applyAnchorPoint = () => {
    updates.batch(() => {
      if (
        !transformNode.current ||
        !nodeTransfromMatrix.current ||
        !newAnchorPoint
      )
        return
      const localAnchorPoint =
        nodeTransfromMatrix.current.inverseTransform(newAnchorPoint)

      setAnchorPoint(transformNode.current, localAnchorPoint, playback.time)

      transformNode.current = null
      initAnchorPoint.current = null
      nodeTransfromMatrix.current = null
      setNewAnchorPoint(null)
      setSnappingItems([])
    })
    undoRedo.commitUndo()
  }

  React.useEffect(() => {
    if (!isListening) {
      if (newAnchorPoint) applyAnchorPoint()
      return
    }
    if (
      !initAnchorPoint.current ||
      !nodeTransfromMatrix.current ||
      !nodeOutlineBox.current
    )
      return

    const globalAnchorPoint = nodeTransfromMatrix.current.transform(
      initAnchorPoint.current
    )
    const newPoint = globalAnchorPoint.add(
      new paper.Point(offsetX / viewport.zoom, offsetY / viewport.zoom)
    )
    const { snappedPoint, items } = snapPointToOutlineBox(
      newPoint,
      nodeOutlineBox.current,
      viewport.zoom
    )
    setSnappingItems(items)
    setNewAnchorPoint(snappedPoint)
  }, [offsetX, offsetY, isListening, viewport.zoom, playback.time])

  return (
    <>
      {nodes.map((node) => {
        const anchorPoint = getAnchorPoint(node, playback.time)
        const transformMatrix = new paper.Matrix(
          getAbsoluteTransformMatrix({
            entity: node,
            time: playback.time,
          })
        )

        const point = transformMatrix.transform(
          new paper.Point(anchorPoint.x, anchorPoint.y)
        )

        const cx = isListening && newAnchorPoint ? newAnchorPoint.x : point.x
        const cy = isListening && newAnchorPoint ? newAnchorPoint.y : point.y

        return (
          <AnchorPoint
            key={`${node.id}-anchor-point`}
            cx={cx}
            cy={cy}
            zoom={viewport.zoom}
            color={
              nodeColors[node.getComponentOrThrow(NodeColorComponent).value]
            }
            onMouseDown={(e) =>
              featureFlags.anchorPointDnd && startAnchorDnd(e, node)
            }
          />
        )
      })}
      {snappingItems.map((item, key) => (
        <Snapping key={key} zoom={viewport.zoom} item={item} />
      ))}
    </>
  )
}

type Props = {
  cx: number
  cy: number
  zoom: number
  color: string
  onMouseDown: (e: React.MouseEvent<SVGElement, MouseEvent>) => void
}
const AnchorPoint = ({ cx, cy, zoom, color, onMouseDown }: Props) => {
  return (
    <circle
      cx={cx}
      cy={cy}
      r={4 / zoom}
      fill="#FFFFFF"
      onMouseDown={onMouseDown}
      stroke={color}
      strokeWidth={1 / zoom}
    />
  )
}

const getNodesToRender = (selection: SelectionSystem) => {
  const selectedNodes = selection.getEntitiesByEntityType(EntityType.Node)
  const selectedKeyframes = selection.getEntitiesByEntityType(
    EntityType.Keyframe
  )
  return Array.from(
    new Set([
      ...selectedKeyframes
        .map((keyframe) => getNode(keyframe))
        .filter((node) => node !== undefined),
      ...selectedNodes,
    ])
  )
}
