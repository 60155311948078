import {
  Entity,
  NodeType,
  NodeTypeComponent,
  Point2D,
  VectorPathsComponent,
} from '@aninix-inc/model'
import featureFlags from '@aninix/core/feature-flags'
import { PathEditor, PathPoint, PathTangent } from '@aninix/core/stores'
import { BezierPoint } from '@aninix/core/vector-helpers'
import * as paper from 'paper'
import * as R from 'ramda'
import { RegionHandle } from './region-point'

export const PATH_EDITOR_EPSILON = 0.01

export type BezierPath = { points: BezierPoint[]; isClosed: boolean }
export type BezierRegion = BezierPath[]

const arePointsVeryClose = (p1: Point2D, p2: Point2D): boolean => {
  const dx = p1.x - p2.x
  const dy = p1.y - p2.y
  return Math.sqrt(dx * dx + dy * dy) < PATH_EDITOR_EPSILON
}

const hasOnlyStartTangent = (point: BezierPoint): boolean => {
  return (
    (point.startTangent.x !== 0 || point.startTangent.y !== 0) &&
    point.endTangent.x === 0 &&
    point.endTangent.y === 0
  )
}

const hasOnlyEndTangent = (point: BezierPoint): boolean => {
  return (
    point.startTangent.x === 0 &&
    point.startTangent.y === 0 &&
    (point.endTangent.x !== 0 || point.endTangent.y !== 0)
  )
}

const hasNoTangents = (point: BezierPoint): boolean => {
  return (
    point.startTangent.x === 0 &&
    point.startTangent.y === 0 &&
    point.endTangent.x === 0 &&
    point.endTangent.y === 0
  )
}

const hasBothTangents = (point: BezierPoint): boolean => {
  return (
    (point.startTangent.x !== 0 || point.startTangent.y !== 0) &&
    (point.endTangent.x !== 0 || point.endTangent.y !== 0)
  )
}

const canMergePoints = (point1: BezierPoint, point2: BezierPoint): boolean => {
  if (hasNoTangents(point1) && hasNoTangents(point2)) return true

  if (
    (hasBothTangents(point1) && hasNoTangents(point2)) ||
    (hasNoTangents(point1) && hasBothTangents(point2))
  )
    return true

  if (
    (hasOnlyStartTangent(point1) && hasNoTangents(point2)) ||
    (hasOnlyEndTangent(point1) && hasNoTangents(point2)) ||
    (hasNoTangents(point1) && hasOnlyStartTangent(point2)) ||
    (hasNoTangents(point1) && hasOnlyEndTangent(point2))
  )
    return true

  return (
    (hasOnlyStartTangent(point1) && hasOnlyEndTangent(point2)) ||
    (hasOnlyEndTangent(point1) && hasOnlyStartTangent(point2))
  )
}

const mergePoints = (target: BezierPoint, source: BezierPoint) => {
  if (hasNoTangents(target)) {
    target.startTangent = source.startTangent
    target.endTangent = source.endTangent
    return
  }

  if (hasOnlyStartTangent(source)) {
    target.startTangent = source.startTangent
  }
  if (hasOnlyEndTangent(source)) {
    target.endTangent = source.endTangent
  }
}

const mergeClosePoints = (
  points: BezierPoint[],
  isClosed: boolean
): BezierPoint[] => {
  if (points.length <= 2) return points
  const result: BezierPoint[] = [points[0]]

  for (let i = 1; i < points.length; i++) {
    const lastPoint = result[result.length - 1]
    const currentPoint = points[i]

    if (arePointsVeryClose(lastPoint.point, currentPoint.point)) {
      if (canMergePoints(lastPoint, currentPoint)) {
        mergePoints(lastPoint, currentPoint)
      } else {
        result.push(currentPoint)
      }
    } else {
      result.push(currentPoint)
    }
  }

  if (isClosed && result.length >= 2) {
    const firstPoint = result[0]
    const lastPoint = result[result.length - 1]

    if (arePointsVeryClose(firstPoint.point, lastPoint.point)) {
      if (canMergePoints(firstPoint, lastPoint)) {
        mergePoints(firstPoint, lastPoint)
        result.pop()
      }
    }
  }

  return result
}

export function svgPathToBezierRegion(providedData: string): BezierRegion {
  const data = new paper.CompoundPath(providedData).pathData
  const splitted = data.split(/(?=[MmVvHhLlCcSQTZz])/)

  const parsedCommands: (string | number)[][] = splitted.map((rawCommand) => {
    const command = rawCommand.charAt(0)
    const points = rawCommand
      .slice(1)
      .replace(/\,/g, ' ')
      .split(' ')
      .filter((p) => p !== '')
      .map((p) => parseFloat(p))
    return [command, ...points]
  })

  const bezierPoints: BezierPoint[][] = []

  for (let i = 0; i < parsedCommands.length; i += 1) {
    const commandWithPoints = parsedCommands[i]
    const command = R.head(commandWithPoints) as string
    const points = R.drop(1, commandWithPoints) as number[]
    const prevPoint = R.last(R.defaultTo([], R.last(bezierPoints)!))!

    if (command === 'M' || command === 'm') {
      bezierPoints.push([])
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({ point: { x: points[0], y: points[1] } })
      )
      continue
    }

    if (command === 'V') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({ point: { x: prevPoint.point.x, y: points[0] } })
      )
      continue
    }

    if (command === 'v') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({
          point: { x: prevPoint.point.x, y: prevPoint.point.y + points[0] },
        })
      )
      continue
    }

    if (command === 'H') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({ point: { x: points[0], y: prevPoint.point.y } })
      )
      continue
    }

    if (command === 'h') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({
          point: { x: prevPoint.point.x + points[0], y: prevPoint.point.y },
        })
      )
      continue
    }

    if (command === 'L') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({ point: { x: points[0], y: points[1] } })
      )
      continue
    }

    if (command === 'l') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({
          point: {
            x: prevPoint.point.x + points[0],
            y: prevPoint.point.y + points[1],
          },
        })
      )
      continue
    }

    if (command === 'C') {
      prevPoint.updateAbsoluteEndTangent({
        x: points[0],
        y: points[1],
      })

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          point: { x: points[4], y: points[5] },
          startTangent: { x: points[2], y: points[3] },
        })
      )
      continue
    }

    if (command === 'c') {
      const absolutePoint = prevPoint.toAbsoluteJson()

      prevPoint.updateAbsoluteEndTangent({
        x: absolutePoint.point.x + points[0],
        y: absolutePoint.point.y + points[1],
      })

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          point: {
            x: absolutePoint.point.x + points[4],
            y: absolutePoint.point.y + points[5],
          },
          startTangent: {
            x: absolutePoint.point.x + points[2],
            y: absolutePoint.point.y + points[3],
          },
        })
      )

      continue
    }

    if (command === 'S') {
      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          startTangent: prevPoint.toAbsoluteJson().endTangent,
          point: { x: points[2], y: points[3] },
          endTangent: { x: points[0], y: points[1] },
        })
      )
      continue
    }

    if (command === 's') {
      const absolutePoint = prevPoint.toAbsoluteJson()

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          startTangent: absolutePoint.endTangent,
          point: {
            x: absolutePoint.point.x + points[2],
            y: absolutePoint.point.y + points[3],
          },
          endTangent: {
            x: absolutePoint.point.x + points[0],
            y: absolutePoint.point.y + points[1],
          },
        })
      )
      continue
    }

    if (command === 'Q') {
      prevPoint.updateAbsoluteEndTangent({ x: points[0], y: points[1] })

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          startTangent: { x: points[0], y: points[1] },
          point: { x: points[2], y: points[3] },
        })
      )
      continue
    }

    if (command === 'T') {
      prevPoint.updateAbsoluteEndTangent({ x: points[0], y: points[1] })

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          startTangent: { x: points[0], y: points[1] },
          point: { x: points[2], y: points[3] },
        })
      )
      continue
    }
  }

  const region = bezierPoints
    .map((points) => {
      const lastCommand = parsedCommands[parsedCommands.length - 1]
      const isClosed = lastCommand[0] === 'Z' || lastCommand[0] === 'z'

      const mergedPoints = mergeClosePoints(points, isClosed)
      return { points: mergedPoints, isClosed }
    })
    .filter(({ points }) => points.length > 0)

  return region
}

export function bezierRegionToSvgPath(region: BezierRegion): string {
  if (region.length === 0) return ''

  let svgPath = ''
  for (const path of region) {
    const points = path.points.map((p) => p.toAbsoluteJson())

    if (points.length === 0) continue

    svgPath += `M ${points[0].point.x} ${points[0].point.y}`

    for (let j = 1; j < points.length; j++) {
      const prev = points[j - 1]
      const curr = points[j]
      svgPath += ` C ${prev.endTangent.x} ${prev.endTangent.y} ${curr.startTangent.x} ${curr.startTangent.y} ${curr.point.x} ${curr.point.y}`
    }

    if (path.isClosed && points.length > 1) {
      const first = points[0]
      const last = points[points.length - 1]
      svgPath += ` C ${last.endTangent.x} ${last.endTangent.y} ${first.startTangent.x} ${first.startTangent.y} ${first.point.x} ${first.point.y} Z`
    }
  }

  return svgPath
}

export const getRegionPointId = (pathIndex: number, bezierIndex: number) =>
  `${pathIndex}-${bezierIndex}`

export function findClosestPoint(region: BezierRegion, point: Point2D) {
  let minDistance = Infinity
  let closestPathIndex = 0
  let closestPointIndex = 0

  region.forEach((path, pathIndex) => {
    path.points.forEach((_, pointIndex) => {
      const currentPoint = path.points[pointIndex].point
      const nextPoint = path.points[(pointIndex + 1) % path.points.length].point

      const distance = getDistanceToSegment(point, currentPoint, nextPoint)
      if (distance < minDistance) {
        minDistance = distance
        closestPathIndex = pathIndex
        closestPointIndex = pointIndex
      }
    })
  })

  return { closestPathIndex, closestPointIndex }
}

export function insertPointIntoSegment(
  region: BezierRegion,
  closestPathIndex: number,
  closestSegmentIndex: number,
  point: Point2D
) {
  const path = region[closestPathIndex]
  const p1 = path.points[closestSegmentIndex]
  const p2 = path.points[(closestSegmentIndex + 1) % path.points.length]

  const t = getParameterAtPoint(point, p1, p2)
  const [newP1Tangents, newPointTangents, newP2Tangents] =
    calculateTangentsForSplit(p1, p2, t)

  p1.updateRelativeEndTangent(newP1Tangents.end)
  p2.updateRelativeStartTangent(newP2Tangents.start)

  const newPoint = new BezierPoint({ point })
  newPoint
    .updateRelativeStartTangent(newPointTangents.start)
    .updateRelativeEndTangent(newPointTangents.end)

  path.points.splice(closestSegmentIndex + 1, 0, newPoint)
}

const getDistanceToSegment = (point: Point2D, p1: Point2D, p2: Point2D) => {
  const A = point.x - p1.x
  const B = point.y - p1.y
  const C = p2.x - p1.x
  const D = p2.y - p1.y

  const dot = A * C + B * D
  const lenSq = C * C + D * D
  let param = -1

  if (lenSq !== 0) param = dot / lenSq

  let xx, yy
  if (param < 0) {
    xx = p1.x
    yy = p1.y
  } else if (param > 1) {
    xx = p2.x
    yy = p2.y
  } else {
    xx = p1.x + param * C
    yy = p1.y + param * D
  }

  const dx = point.x - xx
  const dy = point.y - yy

  return Math.sqrt(dx * dx + dy * dy)
}

const getParameterAtPoint = (
  point: Point2D,
  p1: BezierPoint,
  p2: BezierPoint
): number => {
  const p1Point = p1.point
  const p2Point = p2.point
  const p1EndTangent = p1.endTangent || { x: 0, y: 0 }
  const p2StartTangent = p2.startTangent || { x: 0, y: 0 }

  const cp1 = {
    x: p1Point.x + p1EndTangent.x,
    y: p1Point.y + p1EndTangent.y,
  }
  const cp2 = {
    x: p2Point.x + p2StartTangent.x,
    y: p2Point.y + p2StartTangent.y,
  }

  const getPointAtT = (t: number): Point2D => {
    const mt = 1 - t
    return {
      x:
        mt * mt * mt * p1Point.x +
        3 * mt * mt * t * cp1.x +
        3 * mt * t * t * cp2.x +
        t * t * t * p2Point.x,
      y:
        mt * mt * mt * p1Point.y +
        3 * mt * mt * t * cp1.y +
        3 * mt * t * t * cp2.y +
        t * t * t * p2Point.y,
    }
  }

  const getDistance = (p1: Point2D, p2: Point2D): number => {
    const dx = p2.x - p1.x
    const dy = p2.y - p1.y
    return Math.sqrt(dx * dx + dy * dy)
  }

  let left = 0
  let right = 1
  let bestT = 0
  let bestDistance = Infinity

  for (let i = 0; i < 20; i++) {
    const t1 = left + (right - left) / 3
    const t2 = right - (right - left) / 3

    const p1 = getPointAtT(t1)
    const p2 = getPointAtT(t2)

    const d1 = getDistance(point, p1)
    const d2 = getDistance(point, p2)

    if (d1 < bestDistance) {
      bestDistance = d1
      bestT = t1
    }

    if (d2 < bestDistance) {
      bestDistance = d2
      bestT = t2
    }

    if (d1 < d2) {
      right = t2
    } else {
      left = t1
    }
  }

  const finalStep = 0.01
  for (
    let t = Math.max(0, bestT - finalStep);
    t <= Math.min(1, bestT + finalStep);
    t += 0.001
  ) {
    const p = getPointAtT(t)
    const d = getDistance(point, p)
    if (d < bestDistance) {
      bestDistance = d
      bestT = t
    }
  }

  return bestT
}

interface Tangents {
  start: Point2D
  end: Point2D
}

const calculateTangentsForSplit = (
  p1: BezierPoint,
  p2: BezierPoint,
  t: number
): [Tangents, Tangents, Tangents] => {
  const p1Point = p1.point
  const p2Point = p2.point
  const p1EndTangent = p1.endTangent || { x: 0, y: 0 }
  const p2StartTangent = p2.startTangent || { x: 0, y: 0 }

  const isLine =
    p1EndTangent.x === 0 &&
    p1EndTangent.y === 0 &&
    p2StartTangent.x === 0 &&
    p2StartTangent.y === 0

  if (isLine) {
    return [
      { start: { x: 0, y: 0 }, end: { x: 0, y: 0 } },
      { start: { x: 0, y: 0 }, end: { x: 0, y: 0 } },
      { start: { x: 0, y: 0 }, end: { x: 0, y: 0 } },
    ]
  }

  const P0 = p1Point
  const P1 = {
    x: p1Point.x + p1EndTangent.x,
    y: p1Point.y + p1EndTangent.y,
  }
  const P2 = {
    x: p2Point.x + p2StartTangent.x,
    y: p2Point.y + p2StartTangent.y,
  }
  const P3 = p2Point

  const Q0 = {
    x: P0.x + t * (P1.x - P0.x),
    y: P0.y + t * (P1.y - P0.y),
  }
  const Q1 = {
    x: P1.x + t * (P2.x - P1.x),
    y: P1.y + t * (P2.y - P1.y),
  }
  const Q2 = {
    x: P2.x + t * (P3.x - P2.x),
    y: P2.y + t * (P3.y - P2.y),
  }

  const R0 = {
    x: Q0.x + t * (Q1.x - Q0.x),
    y: Q0.y + t * (Q1.y - Q0.y),
  }
  const R1 = {
    x: Q1.x + t * (Q2.x - Q1.x),
    y: Q1.y + t * (Q2.y - Q1.y),
  }

  const S = {
    x: R0.x + t * (R1.x - R0.x),
    y: R0.y + t * (R1.y - R0.y),
  }

  return [
    {
      start: p1.startTangent || { x: 0, y: 0 },
      end: {
        x: Q0.x - P0.x,
        y: Q0.y - P0.y,
      },
    },
    {
      start: {
        x: R0.x - S.x,
        y: R0.y - S.y,
      },
      end: {
        x: R1.x - S.x,
        y: R1.y - S.y,
      },
    },
    {
      start: {
        x: Q2.x - P3.x,
        y: Q2.y - P3.y,
      },
      end: p2.endTangent || { x: 0, y: 0 },
    },
  ]
}

export enum TangentMirroring {
  None = 'none',
  Angle = 'angle',
  AngleAndLength = 'angle-and-length',
}

export function getUpdatedPointWithTangents(
  point: BezierPoint,
  type: 'start' | 'end',
  position: Point2D,
  mirroring: TangentMirroring
): BezierPoint {
  const start = new paper.Point(
    type === 'start' ? position : point.startTangent
  )
  const end = new paper.Point(type === 'end' ? position : point.endTangent)

  if (mirroring === TangentMirroring.None) {
    if (type === 'start') {
      return point.updateRelativeStartTangent(start)
    } else {
      return point.updateRelativeEndTangent(end)
    }
  }

  if (mirroring === TangentMirroring.AngleAndLength) {
    if (type === 'start') {
      return point
        .updateRelativeStartTangent(start)
        .updateRelativeEndTangent(start.multiply(-1))
    } else {
      return point
        .updateRelativeStartTangent(end.multiply(-1))
        .updateRelativeEndTangent(end)
    }
  }

  if (mirroring === TangentMirroring.Angle) {
    const startLength = Math.sqrt(start.x ** 2 + start.y ** 2)
    const endLength = Math.sqrt(end.x ** 2 + end.y ** 2)

    if (type === 'start') {
      return point.updateRelativeStartTangent(start).updateRelativeEndTangent({
        x: -start.x * (endLength / startLength),
        y: -start.y * (endLength / startLength),
      })
    } else {
      return point
        .updateRelativeStartTangent({
          x: -end.x * (startLength / endLength),
          y: -end.y * (startLength / endLength),
        })
        .updateRelativeEndTangent(end)
    }
  }

  return point
}

export const getPrevAndNextPoints = (
  pathIndex: number,
  path: BezierPath
): {
  prev: Point2D | null
  next: Point2D | null
  prevIndex: number | null
  nextIndex: number | null
} => {
  if (path.points.length === 1) {
    return {
      prev: null,
      prevIndex: null,
      next: null,
      nextIndex: null,
    }
  }
  if (!path.isClosed) {
    if (pathIndex === 0) {
      const nextIndex = 1
      return {
        prev: null,
        prevIndex: null,
        next: path.points[nextIndex].point,
        nextIndex,
      }
    }

    if (pathIndex === path.points.length - 1) {
      const prevIndex = path.points.length - 2
      return {
        prev: path.points[prevIndex].point,
        prevIndex,
        next: null,
        nextIndex: null,
      }
    }
  }

  const prevIndex = (pathIndex - 1 + path.points.length) % path.points.length
  const nextIndex = (pathIndex + 1) % path.points.length

  return {
    prev: path.points[prevIndex].point,
    prevIndex,
    next: path.points[nextIndex].point,
    nextIndex,
  }
}

export const removePathItems = (
  node: Entity,
  items: (PathPoint | PathTangent)[],
  onNodeDelete: () => void
) => {
  const vectorPaths = node.getComponent(VectorPathsComponent)?.value
  if (!vectorPaths?.length) return

  const regions: (BezierRegion | null)[] = vectorPaths.map((vectorPath) =>
    svgPathToBezierRegion(vectorPath.data)
  )

  for (const item of items) {
    if ('tangent' in item) {
      const { regionIndex, pathIndex, pointIndex, tangent } = item
      const point = regions[regionIndex]?.[pathIndex].points[pointIndex]
      if (tangent === 'start') point?.resetStartTangent()
      else point?.resetEndTangent()
    } else {
      const { regionIndex, pathIndex, pointIndex } = item
      const region = regions[regionIndex]
      const path = region![pathIndex]
      path.points.splice(pointIndex, 1)
      if (path.points.length === 0) region!.splice(pathIndex, 1)
      if (region!.length === 0) regions[regionIndex] = null
    }
  }

  const nonNullableRegions = regions.filter((v) => v !== null)

  if (nonNullableRegions.length === 0) {
    onNodeDelete()
    return
  }

  node.updateComponent(VectorPathsComponent, (prev) =>
    prev
      .map((v, i) =>
        regions[i]
          ? {
              ...v,
              data: bezierRegionToSvgPath(regions[i]),
            }
          : null
      )
      .filter((v) => v !== null)
  )
}

export const selectAllPathPoints = (node: Entity, pathEditor: PathEditor) => {
  const vectorPaths = node.getComponent(VectorPathsComponent)?.value
  if (!vectorPaths?.length) return

  const selection: PathPoint[] = []

  for (let regionIndex = 0; regionIndex < vectorPaths.length; regionIndex++) {
    const region = vectorPaths[regionIndex]
    const bezierRegion = svgPathToBezierRegion(region.data)
    for (let pathIndex = 0; pathIndex < bezierRegion.length; pathIndex++) {
      const path = bezierRegion[pathIndex]
      for (let pointIndex = 0; pointIndex < path.points.length; pointIndex++) {
        selection.push({ pointIndex, pathIndex, regionIndex })
      }
    }
  }

  pathEditor.replaceSelection(selection)
}

export const getVectorNode = (selectedNodes: Entity[], buffer: string) => {
  if (!selectedNodes.length) return null

  if (buffer) {
    return (
      selectedNodes.find(
        (node) =>
          node.id === buffer &&
          (featureFlags.renderText
            ? node.getComponent(NodeTypeComponent)?.value !== NodeType.Text
            : true) &&
          node?.getComponent(VectorPathsComponent)?.value
      ) ?? null
    )
  }

  return (
    selectedNodes.find(
      (node) =>
        (featureFlags.renderText
          ? node.getComponent(NodeTypeComponent)?.value !== NodeType.Text
          : true) && node?.getComponent(VectorPathsComponent)?.value
    ) ?? null
  )
}

const DEFAULT_TANGENT_LENGTH = 30

export const createDefaultTangents = () => ({
  start: { x: DEFAULT_TANGENT_LENGTH, y: 0 },
  end: { x: -DEFAULT_TANGENT_LENGTH, y: 0 },
})

export const calculateTangentsFromPoints = (
  prevPoint: Point2D,
  nextPoint: Point2D
) => {
  const dx = prevPoint.x - nextPoint.x
  const dy = prevPoint.y - nextPoint.y

  if (
    Math.abs(dx) < PATH_EDITOR_EPSILON &&
    Math.abs(dy) < PATH_EDITOR_EPSILON
  ) {
    return createDefaultTangents()
  }

  const length = Math.sqrt(dx * dx + dy * dy)
  if (length === 0) {
    return createDefaultTangents()
  }

  const normalizedDx = dx / length
  const normalizedDy = dy / length

  return {
    start: {
      x: normalizedDx * DEFAULT_TANGENT_LENGTH,
      y: normalizedDy * DEFAULT_TANGENT_LENGTH,
    },
    end: {
      x: -normalizedDx * DEFAULT_TANGENT_LENGTH,
      y: -normalizedDy * DEFAULT_TANGENT_LENGTH,
    },
  }
}

export const getUpdatedPointWithToggledTangents = ({
  point,
  path,
  pointIndex,
}: {
  point: BezierPoint
  path: BezierPath
  pointIndex: number
}): BezierPoint => {
  const isStartZero =
    Math.abs(point.startTangent.x) <= PATH_EDITOR_EPSILON &&
    Math.abs(point.startTangent.y) <= PATH_EDITOR_EPSILON
  const isEndZero =
    Math.abs(point.endTangent.x) <= PATH_EDITOR_EPSILON &&
    Math.abs(point.endTangent.y) <= PATH_EDITOR_EPSILON

  const { prev, next } = getPrevAndNextPoints(pointIndex, path)

  console.group('getUpdatedPointWithToggledTangents')
  console.log('prev', prev)
  console.log('next', next)
  console.log('isStartZero', point.startTangent)
  console.log('isEndZero', point.endTangent)
  console.groupEnd()

  if (!prev && !next) return point

  const { start: defaultStart, end: defaultEnd } = createDefaultTangents()

  if (!prev) {
    if (isEndZero) {
      return point.updateRelativeEndTangent(defaultEnd)
    } else {
      return point.resetEndTangent()
    }
  }

  if (!next) {
    if (isStartZero) {
      return point.updateRelativeStartTangent(defaultStart)
    } else {
      return point.resetStartTangent()
    }
  }

  if (isStartZero && isEndZero) {
    const { start, end } = calculateTangentsFromPoints(prev, next)
    return point.updateRelativeStartTangent(start).updateRelativeEndTangent(end)
  }

  if (isStartZero && !isEndZero) {
    return point.updateRelativeStartTangent({
      x: -point.endTangent.x,
      y: -point.endTangent.y,
    })
  }

  if (!isStartZero && isEndZero) {
    return point.updateRelativeEndTangent({
      x: -point.startTangent.x,
      y: -point.startTangent.y,
    })
  }

  return point.resetStartTangent().resetEndTangent()
}

const isPointInRect = (point: Point2D, rect: Rect) => {
  return (
    point.x >= rect.x &&
    point.x <= rect.x + rect.width &&
    point.y >= rect.y &&
    point.y <= rect.y + rect.height
  )
}

export type AbsoluteItem = {
  point: paper.Point
  startTangent: paper.Point
  endTangent: paper.Point
  indexes: { regionIndex: number; pathIndex: number; pointIndex: number }
}

export const getIntersectionItems = (
  absoluteGlobalItems: AbsoluteItem[],
  selectionRectBoundingRect: Rect
) => {
  const items: (PathPoint | PathTangent)[] = []

  for (const {
    point,
    startTangent,
    endTangent,
    indexes,
  } of absoluteGlobalItems) {
    if (isPointInRect(point, selectionRectBoundingRect)) items.push(indexes)
    if (isPointInRect(startTangent, selectionRectBoundingRect))
      items.push({ ...indexes, tangent: 'start' })
    if (isPointInRect(endTangent, selectionRectBoundingRect))
      items.push({ ...indexes, tangent: 'end' })
  }

  return items
}

export const omitSelectedPoints = (
  absoluteItems: AbsoluteItem[],
  selectedPoints: PathPoint[]
) => {
  return absoluteItems.filter(
    (item) =>
      !selectedPoints.some((selected) => R.equals(selected, item.indexes))
  )
}

export const getRelativeItems = ({
  regionIndex,
  pathIndex,
  pointIndex,
  handle,
  region,
}: {
  regionIndex: number
  pathIndex: number
  pointIndex: number
  handle: RegionHandle
  region: BezierRegion
}) => {
  const isPoint = handle === RegionHandle.Point

  if (!isPoint)
    return [
      {
        regionIndex,
        pathIndex,
        pointIndex,
        tangent: handle === RegionHandle.StartTangent ? 'start' : 'end',
      },
    ]

  const point = region[pathIndex].points[pointIndex].point
  return findNearPositionPoints(region, regionIndex, point)
}

export const findNearPositionPoints = (
  region: BezierRegion,
  regionIndex: number,
  point: Point2D
) => {
  const items: PathPoint[] = []

  for (let pathIndex = 0; pathIndex < region.length; pathIndex++) {
    const path = region[pathIndex]
    for (let pointIndex = 0; pointIndex < path.points.length; pointIndex++) {
      const p = path.points[pointIndex]
      if (
        Math.abs(p.point.x - point.x) <= PATH_EDITOR_EPSILON &&
        Math.abs(p.point.y - point.y) <= PATH_EDITOR_EPSILON
      ) {
        items.push({
          regionIndex,
          pathIndex,
          pointIndex,
        })
      }
    }
  }

  return items
}

export type RegionPointSelection = {
  isPointSelected: boolean
  isPrevPointSelected: boolean
  isNextPointSelected: boolean
  isStartTangentSelected: boolean
  isEndTangentSelected: boolean
  isNearStartTangentSelected: boolean
  isNearEndTangentSelected: boolean
  isNextStartTangentSelected: boolean
  isPrevEndTangentSelected: boolean
}

export const getRegionPointSelection = (args: {
  pathEditor: PathEditor
  pathIndex: number
  pointIndex: number
  region: BezierRegion
  regionIndex: number
}): RegionPointSelection => {
  const { regionIndex, pathIndex, pointIndex, region, pathEditor } = args

  const point = region?.[pathIndex]?.points?.[pointIndex]?.point

  if (!point)
    return {
      isPointSelected: false,
      isPrevPointSelected: false,
      isNextPointSelected: false,
      isStartTangentSelected: false,
      isEndTangentSelected: false,
      isNearStartTangentSelected: false,
      isNearEndTangentSelected: false,
      isNextStartTangentSelected: false,
      isPrevEndTangentSelected: false,
    }

  const nearPositionPoints = findNearPositionPoints(region, regionIndex, point)

  const { prevIndex, nextIndex } = getPrevAndNextPoints(
    pointIndex,
    region[pathIndex]
  )

  const isPointSelected = pathEditor.isSelected({
    pointIndex,
    pathIndex,
    regionIndex,
  })

  const isPrevPointSelected =
    prevIndex !== null &&
    pathEditor.isSelected({
      pointIndex: prevIndex,
      pathIndex,
      regionIndex,
    })

  const isNextPointSelected =
    nextIndex !== null &&
    pathEditor.isSelected({
      pointIndex: nextIndex,
      pathIndex,
      regionIndex,
    })

  const isStartTangentSelected = pathEditor.isSelected({
    pointIndex,
    pathIndex,
    regionIndex,
    tangent: 'start',
  })

  const isEndTangentSelected = pathEditor.isSelected({
    pointIndex,
    pathIndex,
    regionIndex,
    tangent: 'end',
  })

  const isNearStartTangentSelected = nearPositionPoints.some((p) =>
    pathEditor.isSelected({
      ...p,
      tangent: 'start',
    })
  )

  const isNearEndTangentSelected = nearPositionPoints.some((p) =>
    pathEditor.isSelected({
      ...p,
      tangent: 'end',
    })
  )

  const isNextStartTangentSelected =
    nextIndex !== null &&
    pathEditor.isSelected({
      pointIndex: nextIndex,
      pathIndex,
      regionIndex,
      tangent: 'start',
    })

  const isPrevEndTangentSelected =
    prevIndex !== null &&
    pathEditor.isSelected({
      pointIndex: prevIndex,
      pathIndex,
      regionIndex,
      tangent: 'end',
    })

  return {
    isPointSelected,
    isPrevPointSelected,
    isNextPointSelected,
    isStartTangentSelected,
    isEndTangentSelected,
    isNearStartTangentSelected,
    isNearEndTangentSelected,
    isNextStartTangentSelected,
    isPrevEndTangentSelected,
  }
}
