import * as React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { Project, SelectionSystem } from '@aninix-inc/model'
import { Playback, Tool, Tools, Viewport } from '../../stores'
import { useTrackKeyPress } from './track-key-press'

type Payload = {
  tools: Tools
  playback: Playback
  viewport: Viewport
  project: Project
  onKeyPress: (hotkey: string) => void
}
export function usePlaybackHotkeys({
  tools,
  playback,
  project,
  onKeyPress,
}: Payload) {
  /**
   * @description determine how long should it take to affect holdSpace event
   */
  const spacePressThreshold = 150
  const isSpaceHolding = React.useRef<boolean>(false)
  /**
   * @description helper to handle space holding event
   */
  const spacePressedTimeout = React.useRef<any>(null)
  const previousTool = React.useRef<Tool>(tools.activeTool)

  const trackKeyPress = useTrackKeyPress(onKeyPress)

  // @NOTE: press space
  useHotkeys(
    'space',
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      trackKeyPress('space')

      if (isSpaceHolding.current === true) {
        return
      }

      spacePressedTimeout.current = setTimeout(() => {
        isSpaceHolding.current = true
        previousTool.current = tools.activeTool
        tools.changeTool(Tool.Hand)
      }, spacePressThreshold)
    },
    { keydown: true }
  )

  // @NOTE: release space
  useHotkeys(
    'space',
    (e) => {
      e.preventDefault()
      e.stopPropagation()

      if (isSpaceHolding.current === true) {
        tools.changeTool(previousTool.current)
        isSpaceHolding.current = false
        return
      }

      clearTimeout(spacePressedTimeout.current)
      playback.togglePlaying()
    },
    { keyup: true }
  )

  // @NOTE: go to start
  useHotkeys('shift+f', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('shift+f')
    playback.toStart()
  })

  // @NOTE: go to previous keyframe
  useHotkeys('ctrl+f,meta+f,j', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('ctrl+f,meta+f,j')
    playback.toPreviousKeyframe()
  })

  // @NOTE: go to previous frame
  useHotkeys('f,meta+left,ctrl+left', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('f,meta+left,ctrl+left')
    playback.toPreviousTimeUnit()
  })

  // @NOTE: go to previous frame by 10
  useHotkeys('shift+meta+left,shift+ctrl+left', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('shift+meta+left,shift+ctrl+left')
    playback.toPreviousTimeUnit(10)
  })

  // @NOTE: go to next keyframe
  useHotkeys('ctrl+g,meta+g,k', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('ctrl+g,meta+g,k')
    playback.toNextKeyframe()
  })

  // @NOTE: go to next frame
  useHotkeys('g,meta+right,ctrl+right', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('g,meta+right,ctrl+right')
    playback.toNextTimeUnit()
  })

  // @NOTE: go to next frame by 10
  useHotkeys('shift+meta+right,shift+ctrl+right', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('shift+meta+right,shift+ctrl+right')
    playback.toNextTimeUnit(10)
  })

  // @NOTE: go to end
  useHotkeys('shift+g', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('shift+g')
    playback.toEnd()
  })
  // @NOTE: deselect all
  useHotkeys('esc', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('esc')
    project.getSystemOrThrow(SelectionSystem).deselectAll()
    tools.changeTool(Tool.Selection)
  })

  return {}
}
